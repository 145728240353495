import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import {API_BASE_URL} from '../config';
import {NavLink} from "react-router-dom";
import ModalAccount from './ModalAccount';

const MainSearchBar = (navBarState) => {
    const [searchText, setSearchText] = useState('');
    const [results, setResults] = useState([]);
    const [modalState, setModalState] = useState(false);

    const searchRef = useRef(null);
    const accountRef = useRef(null);

    // Toggle account modal
    const handleAccountModal = () => {
        setModalState((prev) => !prev);
    };

    // Handle clicks outside both search and account modal
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setResults([]);
            }
            if (accountRef.current && !accountRef.current.contains(event.target)) {
                setModalState(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Handle search input change
    useEffect(() => {
        if (searchText.trim() === '') {
            setResults([]);
            return;
        }

        const timer = setTimeout(() => {
            axios.post(`${API_BASE_URL}/search/`, { searchText })
                .then((response) => {
                    setResults(response.data);
                })
                .catch((error) => {
                    console.error('Error fetching search results:', error);
                });
        }, 500);

        return () => clearTimeout(timer);
    }, [searchText]);

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleFocus = () => {
        if (searchText.trim() !== '') {
            axios.post(`${API_BASE_URL}/search/`, { searchText })
                .then((response) => {
                    setResults(response.data);
                })
                .catch((error) => {
                    console.error('Error fetching search results on focus:', error);
                });
        }
    };

    return (<div className="">
        <div
            className={`main-searchbar display-flex justify-content-space-between transition-height ${
                navBarState ? "main-searchbar-container-contained" : "main-searchbar-container-full"
            }`}
        >
            <div className="input-search">
                <i className="fa-solid fa-magnifying-glass mr-10"></i>
                <input
                    onChange={handleSearchChange}
                    onFocus={handleFocus}
                    type="text"
                    className="search-input"
                    placeholder="Rechercher..."
                    value={searchText}
                />
            </div>

            <div>
                <div className="display-flex">
                    <div ref={accountRef} className="">
                        <div
                            onClick={handleAccountModal}
                            className="display-flex align-items-center gap-10 cursor-pointer"
                        >
                            <span className="user-pp">{localStorage.user_name.charAt(0)}</span>
                            <strong>{localStorage.user_name}</strong>
                            <i className="fas fa-chevron-down"></i>
                        </div>
                        {modalState && <ModalAccount />}
                    </div>
                </div>
            </div>
            </div>

        <div ref={searchRef} className={"searchbar-results"}>
            {/* Render search results here */}
            {results.length > 0 && (<div className="search-results">
                <div className={"p-15"} style={{overflowY: "scroll", maxHeight: "50vh"}}>
                    {/* Render Customer Results */}
                    {results.some(result => result.type === 'customer') && (<div>
                        <h4 className={"mt-0 color-third "}>Clients</h4>

                        {results.filter(result => result.type === 'customer').map((result, index) => (

                                <NavLink className={"text-decoration-none"}

                                         to={`/client/${result.id}`}
                                         key={index}
                                         onClick={() => setResults([])}  // Clear results on click
                                >                            <div className={"display-flex gap-10 search-item-found"}>

                                <div className={"display-flex gap-10 align-items-center"}>
                                        <i className="fa-solid fa-user mr-10 color-third mr-10"></i>
                                        <strong className={"color-primary"}
                                                dangerouslySetInnerHTML={{__html: result.name_1}}/>
                                    </div></div>
                                </NavLink>))}
                        <hr className={"my-20"}/>
                    </div>)

                    }

                    {/* Render Position Results */}
                    {results.some(result => result.type === 'position') && (<div>
                        <h4 className={" mt-0 color-third "}>Projets </h4>

                        {results.filter(result => result.type === 'position').map((result, index) => (
                            <NavLink className={"text-decoration-none"}
                                     to={`/positions/${result.id}`}
                                     key={index}
                                     onClick={() => setResults([])}  // Clear results on click
                            >
                                <div className={"display-flex gap-10 search-item-found"}>

                                    <div className={"display-flex gap-10 align-items-center"}>
                                        <i className="fa-solid fa-list-check mr-10 color-third mr-10"></i>
                                        <strong className={"color-primary"}
                                                dangerouslySetInnerHTML={{__html: result.name_1}}/>
                                    </div>
                            <i className={"color-third"} dangerouslySetInnerHTML={{__html: result.name_2}}/>
                                </div>
                        </NavLink>

                        ))}
                        <hr className={"my-20"}/>
                    </div>)}{/* Render Sites Results */}
                    {results.some(result => result.type === 'website') && (<div>
                        <h4 className={" mt-0 color-third "}>Sites
                            internet </h4>


                        {results.filter(result => result.type === 'website').map((result, index) => (

                            <NavLink className={"text-decoration-none"}
                                     to={`https://${result.url}`}
                                     key={index}
                                     target={"_blank"}
                                     onClick={() => setResults([])}  // Clear results on click
                            >                            <div className={"display-flex gap-10 search-item-found"}>

                            <div className={"display-flex gap-10 align-items-center"}>
                                    <i className={"fa-regular fa-globe-pointer mr-10 color-third"}/>
                                    <strong className={"color-primary"}
                                            dangerouslySetInnerHTML={{__html: result.url}}/>
                                </div></div>
                            </NavLink>))}
                        <hr className={"my-20"}/>

                    </div>)

                    }

                    {results.some(result => result.type === 'website_visu') && (<div>
                        <h4 className={" mt-0 color-third "}>Visus
                        </h4>

                        {results.filter(result => result.type === 'website_visu').map((result, index) => (

                            <NavLink className={"text-decoration-none"}
                                     to={`https://visu.solution-digitale.ch/${result.url}`}
                                     key={index}
                                     target={"_blank"}
                                     onClick={() => setResults([])}  // Clear results on click
                            >
                                <div className={"display-flex gap-10 search-item-found"}>
                                    <div className={"display-flex gap-10 align-items-center"}>
                                        <i className="fa-sharp color-third fa-solid fa-traffic-cone mr-10"></i>
                                        <strong className={"color-primary"}
                                                dangerouslySetInnerHTML={{__html: result.url}}/>
                                    </div></div>
                            </NavLink>))}

                    </div>)}
                </div>
            </div>)}
        </div>
    </div>);
};

export default MainSearchBar;
