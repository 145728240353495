import React, {useEffect, useState} from 'react';
import axios from "axios";
import {API_BASE_URL} from "../../config";

const TicketsNavbar = ({toggleTicketModal}) => {
    const [categories, setCategories] = useState([]);
    const [departmentTickets, setDepartmentTickets] = useState([]);

    // Fetch categories and their corresponding ticket data
    useEffect(() => {
        axios
            .post(`${API_BASE_URL}/get_all_tickets_categories`)
            .then((res) => {
                console.log(res.data);
                setCategories(res.data);

                // Fetch tickets for each department category
                const fetchTickets = res.data.map((category) =>
                    axios
                        .post(`${API_BASE_URL}/get_all_tickets_per_category`, {id: category.id})
                        .then((res) => ({
                            id: category.id,
                            name: category.name,
                            tickets: res.data,
                        }))
                        .catch((error) => {
                            console.error(`Error fetching tickets for department ${category.name}:`, error);
                            return {id: category.id, name: category.name, tickets: []};
                        })
                );

                // Wait for all requests to complete
                Promise.all(fetchTickets).then((results) => {
                    console.log(results);
                    setDepartmentTickets(results); // Store tickets by department
                });
            })
            .catch((error) => {
                console.error("Error fetching categories:", error);
            });
    }, []);

    return (
        <div>

                    {
                        /*

            <div className="content">
                <div className="tickets-categories">
                    <div className={"display-flex flex-direction-column"}>
                        <button className={"btn-submit-account ml-0 my-10"} onClick={toggleTicketModal}>
                            <i className="fa-kit fa-solid-ticket-clock mr-10"/>Mes tickets ouverts
                        </button>
                        <button className={"btn-submit-account ml-0 my-10"} onClick={toggleTicketModal}>
                            <i className="fa-kit fa-solid-ticket-circle-check mr-10"/> Mes tickets résolus
                        </button>
                    </div>

                    {departmentTickets.map(({id, name, tickets}) => {
                        // Calculate the number of opened tickets and the last updated date
                        const totalTickets = tickets.length;
                        const lastUpdated = tickets[0].last_updated_at

                        return (
                            <div key={id} className="tickets-category display-flex">
                                <div className={"left-container-ticket"}></div>
                                <span className="category-name">{name}</span>
                                <span className="category-tickets">
                                    {totalTickets} ticket{totalTickets !== 1 ? "s" : ""} ouvert{totalTickets !== 1 ? "s" : ""}
                                </span>
                                <span className="category-last-updated">
                                    Dernière mise à jour : {lastUpdated ? new Date(lastUpdated).toLocaleString() : "N/A"}
                                </span>
                            </div>
                        );
                    })}

                </div>
            </div>
                    */

                    }
        </div>
    );
};

export default TicketsNavbar;