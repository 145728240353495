import React, {useRef, useState} from "react";

const CommentsSection = ({ comments, onReply }) => {


    const endPage = useRef(null);

    const scrollToBottom = () => {

        // Function to scroll to the form (bottom of the page)
        if (endPage.current) {
            endPage.current.scrollIntoView({
                behavior: "smooth",  // Smooth scrolling effect
                block: "start",      // Align to the start of the element
            });
        }

    window.scrollTo({
        top: document.body.scrollHeight,  // Scroll to the bottom of the page
        behavior: "smooth",               // Smooth scrolling effect
    });
};
    const groupComments = (comments) => {
        const grouped = {};

        comments.forEach((comment) => {
            if (comment.comment_to) {
                // This is a reply
                if (!grouped[comment.comment_to]) {
                    grouped[comment.comment_to] = { replies: [] };
                }
                grouped[comment.comment_to].replies.push(comment);
            } else {
                // This is a standard comment
                grouped[comment.id] = grouped[comment.id] || { replies: [] };
                grouped[comment.id].comment = comment;
            }
        });

        // Convert the grouped object to an array of parent comments with replies
        return Object.values(grouped).filter(group => group.comment);
    };

    const groupedComments = groupComments(comments);
    const [hoveredCommentId, setHoveredCommentId] = useState(null);

    return (
        <div className="mb-20">
            {groupedComments.map(({ comment, replies }) => (
                <div key={comment.id}>
                    {/* Main Comment */}
                    <div
                        className="ticket-comment"
                        onMouseEnter={() => setHoveredCommentId(comment.id)}
                        onMouseLeave={() => setHoveredCommentId(null)}
                    >
                        <div>
                            <span dangerouslySetInnerHTML={{ __html: comment.content }} />
                            Par <strong><span dangerouslySetInnerHTML={{ __html: comment.username }} /></strong>&nbsp;le&nbsp;
                            <span>{new Date(comment.date).toLocaleString()}</span>
                        </div>

                        {/* Reply button visible on hover */}
                        {hoveredCommentId === comment.id && (
                            <button
                                className="reply-button"
                                onClick={() => {
                                    onReply(comment);
                                    scrollToBottom();
                                }}
                                style={{
                                    marginLeft: 10,
                                    cursor: "pointer",
                                    background: "transparent",
                                    border: "none",
                                    color: "blue",
                                    textDecoration: "underline",
                                }}
                            >

                                <i className="fa-solid fa-reply color-third" style={{fontSize: "1.6rem"}}></i>
                            </button>
                        )}
                    </div>

                    {/* Replies */}
                    {replies.map((reply) => (
                        <div className={"display-flex flex-direction-row-reverse"}>

                            <div
                                key={reply.id}
                                className="ticket-comment reply"
                            style={{
                                marginLeft: "0 20px",
                                marginBottom: 10,
                                padding: "0 20px 20px 20px",
                                backgroundColor: "#0004ff96",
                                color: "white",
                                width: "70%",
                                borderRadius: "5px",
                            }}
                        >
                            <div>
                                <span dangerouslySetInnerHTML={{ __html: reply.content }} />
                                Par <strong><span dangerouslySetInnerHTML={{ __html: reply.username }} /></strong>&nbsp;le&nbsp;
                                <span>{new Date(reply.date).toLocaleString()}</span>
                            </div>
                        </div>
                        </div>
                    ))}
                </div>
            ))}
            {/* The form component that we want to scroll to */}
            <div ref={endPage}>

            </div>
        </div>

    );
};

export default CommentsSection;
