import React, {useEffect, useState} from "react";

import '../../assets/css/Ticket.css'
import {NavLink, useNavigate} from "react-router-dom";
import axios from "axios";
import {API_BASE_URL} from "../../config";

const TicketsTable = ({tickets}) => {
    console.log(tickets)
    const navigate = useNavigate();
    const [assignatedUsers, setAssignatedUsers] = useState([])

    // Infos du ticket
    useEffect(() => {
        // Fetch ticket info
        axios
            .post(`${API_BASE_URL}/get_user_name`, {ids: tickets})
            .then((res) => {
                console.log(res)
                const ticketData = res.data[0];
                console.log(res.data)
                setAssignatedUsers(ticketData);
              })
            .catch((error) => console.error("Error fetching ticket:", error));
    }, [tickets]); // Only depends on `id`

    const handleRowClick = (ticketId) => {
        navigate(`/ticket/${ticketId}`); // Update this path to match your routing setup
    };
    return (
        <div className={"flex-4 tickets-table-container display-flex flex-direction-column overflow-auto box-shadow p-20 border-radius-10"}>
            <div className={""}>
                <div className="tickets-table-container" style={{overflow: "auto"}}>
                    {tickets.length === 0 ? (<p>Il n'y a pas encore de tickets.</p>) : (<table className="tickets-table">
                        <thead>
                        </thead>
                        <tbody>
                        {tickets.map((ticket) => (
                            <tr
                                key={ticket.id}
                                onClick={() => handleRowClick(ticket.id)}
                                style={{cursor: "pointer", display: "flex", alignItems: "center"}}
                            >
                                <td style={{minWidth: 80, textAlign: "center", flex: 1}}>
                    <span className={`ticket-span-alias bg-color-${(ticket.alias).toLowerCase()}`}
                          style={{minWidth: 70, textAlign: "center"}}>
                        <strong>#{ticket.id} {ticket.alias}</strong>
                    </span>
                                </td>
                                <td style={{flex: 2}}><span>{ticket.ticket_type || "Non renseigné"}</span></td>

                                <td style={{flex: 3, minWidth: 120}} className="gap-5 ticket-assigned-people">
                                    {ticket.assigned_usernames_and_colors &&
                                        ticket.assigned_usernames_and_colors.map((user, index) => {
                                            console.log(user);

                                            return (
                                                <span
                                                    key={index} // Use index as a unique key
                                                    className="span-ticket-people"
                                                    style={{
                                                        color: user.color,
                                                        border: `solid 2px ${user.color}`, // Fixed border style
                                                    }}
                                                >
          {user.username}
        </span>
                                            );
                                        })}
                                </td>


                                <td className={"text-center display-flex flex-1 "}
                                    style={{minWidth: 120, display: "flex"}}>
                    <span
                        style={{backgroundColor: ticket.priority_color}}
                        className={"priority-ticket ticket-status"}
                    >
                        <strong>{ticket.priority_level || "Normal"}</strong>
                    </span>
                                </td>
                                <td style={{flex: 1}}>
                                    <span>{new Date(ticket.created_at).toLocaleString() || "N/A"}</span></td>

                                <td style={{flex: 1}} className={"text-center flex-1"} style={{minWidth: 110}}>
                    <span className={`ticket-status-${ticket.status} ticket-status`}>
                        {ticket.status}
                    </span>
                                </td>
                                <td style={{flex: 1}}>
                    <span>
                        {ticket.customer_name1 && ticket.customer_name2 ? `${ticket.customer_name1} ${ticket.customer_name2}` : ticket.customer_name1 || ticket.customer_name2 || ""}
                    </span>
                                </td>
                            </tr>))}
                        </tbody>
                    </table>)}
                </div>
            </div>
        </div>
    );
};

export default TicketsTable;
