import React, {useEffect, useState} from 'react';
import axios from "axios";
import {API_BASE_URL} from "../config";
import Table from "../components/Table";
import Modal from 'react-modal';

const UsersList = () => {
    const [users, setUsers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const [user, setUser] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('')
    const [department, setDepartment] = useState('')
    const [registerMessage, setRegisterMessage] = useState('');
    const roles = ['Administrateur', 'Editeur', 'Consultant'];
    const [departments, setDepartments] = useState([]);
    const [password, setPassword] = useState('');

    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_all_departments`, {user, email, password, role})
            .then(response => {
                setDepartments(response.data);
            })
            .catch(error => {
                // Handle network errors
                console.error('Error:', error);
                setRegisterMessage('An error occurred. Please try again later.');
            });

        axios.post(`${API_BASE_URL}/get_users`)
            .then(res => {
                const { data } = res;
                if (data && data.length > 0) {
                    const usersArray = data.map(user => ({
                        ...user,
                        departmentNames: user.department_names.split(',') // Convert to array
                    }));
                    console.log(usersArray);
                    setUsers(usersArray);
                }
            })
            .catch(error => {
                console.error('Error fetching user data:', error);
            });
    }, [email, password, role, user]);


    useEffect(() => {
        document.title = 'X | Utilisateurs'
    }, []);
    const generateStrongPassword = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const specialChars = '!@#$%^&*()-_=+[{]}|;:,<.>/?';
        let strongPassword = '';

        for (let i = 0; i < 9; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            strongPassword += chars[randomIndex];
        }

        const randomSpecialChar = specialChars[Math.floor(Math.random() * specialChars.length)];
        strongPassword += randomSpecialChar;

        // Shuffle the password characters to mix things up
        strongPassword = shuffleString(strongPassword);

        setPassword(strongPassword);
    };

    // Function to shuffle a string (Fisher-Yates algorithm)
    const shuffleString = (str) => {
        const array = str.split('');
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array.join('');
    };

    const handleRegister = (e) => {
        e.preventDefault();

        axios.post(`${API_BASE_URL}/register`, {user, email, password, role})
            .then(response => {
                if (response.data.status === 'ok') {
                    // Successful login
                    // Perform actions such as setting user session, redirecting, etc.
                    setRegisterMessage('Utilisateur enregistré avec succès');

                } else {
                    // Wrong credentials
                    setRegisterMessage('Une erreur est survenue.');
                }
            })
            .catch(error => {
                // Handle network errors
                console.error('Error:', error);
                setRegisterMessage('An error occurred. Please try again later.');
            });

    }

    return (<div>
        <h2>Utilisateurs</h2>

        <Table data={users} headersData={['users', 'email', 'role', 'department']} hidePagination={true}
               headers={['Utilisateur', 'Email', 'Rôle', 'Département']}/>

        <div style={{marginTop: "20px"}}>
            <button onClick={openModal} className={"btn-submit-account ml-0"}>
                <i className={"fas fa-solid fa-plus mr-10"}/> Ajouter un utilisateur
            </button>
        </div>
        {/* Modal for Register Component */}
        <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Ajouter un utilisateur"
            className="modal"
            overlayClassName="overlay"
            shouldCloseOnOverlayClick={true} // This will close the modal when the overlay is clicked
        >
            <div className="register-container">
                <form className="register-form" onSubmit={handleRegister}>
                    <h2 className="title">Création d'un utilisateur</h2>
                    <div className="input-container mb-0">
                        <div className="input-icon">
                            <i className="fas fa-user  color-third"></i>
                        </div>
                        <input className="input" type="text" placeholder="Prénom et nom" required
                               autoFocus={true}
                               value={user} onChange={(e) => setUser(e.target.value)}/>
                    </div>
                    <div className="input-container mb-0">
                        <div className="input-icon">
                            <i className="fas fa-envelope  color-third"></i>
                        </div>
                        <input className="input" type="email" placeholder="Email" required
                               value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className="input-container mb-0 ">
                        <div className="input-icon">
                            <i className="fas fa-lock  color-third"></i>
                        </div>
                        <input
                            className="input"
                            type="text"
                            placeholder="Mot de passe"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span className="btn-generate" onClick={generateStrongPassword}><i
                            className="fa-solid fa-arrows-rotate"></i></span>

                    </div>
                    <div className="input-container custom-select mb-0">
                        <div className="input-icon">
                            <i className="fa-solid fa-industry color-third"></i>
                        </div>
                        {/* Dropdown for roles */}
                        <select
                            className="input"
                            value={department}
                            onChange={(e) => setDepartment(e.target.value)}
                            required
                        >
                            <option value="" disabled hidden>Choisir un département</option>
                            {departments.map((department) => (
                                <option key={department.id} value={department.id}>{department.name}</option>))}
                        </select>
                    </div>
                    <div className="input-container custom-select">
                        <div className="input-icon">
                            <i className="fa-solid fa-user-lock color-third"></i>
                        </div>
                        {/* Dropdown for roles */}
                        <select
                            className="input"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            required
                        >
                            <option value="" disabled hidden>Choisir un rôle</option>
                            {roles.map((roleOption) => (
                                <option key={roleOption} value={roleOption}>{roleOption}</option>))}
                        </select>
                    </div>
                    {/* Display login error message */}
                    {registerMessage && <p className="error-message">{registerMessage}</p>}
                    <div className={"display-flex justify-content-end gap-10 btn-container"}>

                        <button className="btn-submit-account cancel" style={{backgroundColor: "#ce3b2a"}}
                                onClick={closeModal}>Annuler
                        </button>
                        <button className="btn-submit-account" type="submit">Enregistrer un utilisateur</button>
                    </div>

                </form>
            </div>
        </Modal>
    </div>)
}

export default UsersList;
