import React, {useState} from 'react';
import TicketsNavbar from "./TicketsNavbar";
import {NavLink} from "react-router-dom";

const TicketsSideBar = ({toggleTicketModal}) => {

    const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
    const [fetchTicketsTrigger, setFetchTicketsTrigger] = useState(false); // Trigger for fetching tickets

    return (
        <div className={"flex-1 tickets-table-container display-flex flex-direction-column overflow-auto box-shadow p-20 border-radius-10"}>
            <TicketsNavbar toggleTicketModal={toggleTicketModal}/>
            <div className={" sidebar-tickets"}>
                <span className={"btn-submit-account ml-0"} onClick={toggleTicketModal}>
                    <i className={"fas fa-solid fa-plus mr-10"}/> Créer un ticket
                </span>
                <NavLink to={'/mes-tickets'} className={"text-decoration-none"}>
                    <span className={"btn-submit-account ml-0"}>
                        <i className={"fas fa-solid fa-bars mr-10"}/> Mes tickets ouverts
                    </span>
                </NavLink>
                <NavLink to={'/mes-tickets-assignes'} className={"text-decoration-none"}>
                    <span className={"btn-submit-account ml-0"}>
                        <i className={"fa-kit fa-solid-bars-circle-user mr-10"}/> Mes tickets assignés
                    </span>
                </NavLink>
            </div>
        </div>
    );
};

export default TicketsSideBar;