import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import axios from "axios";
import {API_BASE_URL} from "../../config";
import ImageResize from 'quill-image-resize-module-react';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../assets/css/Ticket.css'


// Register the image resize module
Quill.register('modules/imageResize', ImageResize);


// Add the Quill modules configuration
const quillModules = {
    toolbar: [[{header: [1, 2, 3, false]}], ['bold', 'italic', 'underline', 'strike'], // Text styling
        [{list: 'ordered'}, {list: 'bullet'}], ['blockquote', 'code-block'], ['link', 'image'], // Links and images
        [{align: []}], [{color: []}, {background: []}], // Color options
        ['clean'], // Remove formatting
    ], clipboard: {
        matchVisual: false,
    }, // Enable image resizing
    imageResize: {
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
};



// Formats allowed in the editor
const quillFormats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'blockquote', 'code-block', 'link', 'image', 'align', 'color', 'background',];
const TicketModal = ({closeModal}) => {
    const [department, setDepartment] = useState("");
    const [departments, setDepartments] = useState([]);
    const [emergencyLevel, setEmergencyLevel] = useState("");
    const [priorityLevels, setPriorityLevels] = useState([])
    const [departementUsers, setDepartementUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [description, setDescription] = useState()
    const [title, setTitle] = useState()
    const [customer, setCustomer] = useState([])
    const [customers, setCustomers] = useState([])
    const [ticketType, setTicketType] = useState([])
    const [ticketTypes, setTicketTypes] = useState([])

    useEffect(() => {

        axios.post(`${API_BASE_URL}/get_all_customers`)
            .then(res => {
                const filteredData = res.data.datas.filter(item => item.url !== '307.solution-digitale.ch' && item.url !== '308.solution-digitale.ch');
                const sortedData = filteredData.sort((a, b) => b.timestamp - a.timestamp);
                setCustomers(sortedData);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });

        axios.post(`${API_BASE_URL}/get_all_departments`)
            .then(res => {
                setDepartments(res.data);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
        axios.post(`${API_BASE_URL}/get_tickets_priority_levels`)
            .then(res => {
                setPriorityLevels(res.data);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []);

    useEffect(() => {
        console.log(department)
        axios
            .post(`${API_BASE_URL}/get_users_per_departement`, {department})
            .then((res) => {
                setDepartementUsers(res.data);
                console.log(res.data)
            })
            .catch((error) => {
                console.error("Error fetching department users:", error);
            });
    }, [department]);


    const saveTicket = () => {
        const user_id = localStorage.user_id;
        const customer_id = customer.id
        const datas = {
            department,
            selectedUser,
            user_id,
            emergencyLevel,
            customer_id,
            ticketType, // Ensure you have a state variable for title
            description, // Ensure you have a state variable for description
        };

        console.log(ticketType)

        axios
            .post(`${API_BASE_URL}/create_ticket`, { datas })
            .then((res) => {
                console.log("Ticket created successfully:", res.data);
                if (res.status === 201) {
                    closeModal("saved")
                }
                // Show a success message or handle the response
            })
            .catch((error) => {
                console.error("Error creating ticket:", error);
                // Show an error message
            });
    };

    useEffect(() => {
        console.log('ok')
        axios
            .post(`${API_BASE_URL}/get_tickets_types`, {department})
            .then((res) => {
                console.log(res)
                setTicketTypes(res.data)
            })


    }, [department]);

    const handleCreateTicket = (e) => {
        e.preventDefault();
        saveTicket();
    };

    return (<div>
        <Modal
            isOpen
            onRequestClose={closeModal}
            contentLabel="Créer un ticket"
            className="modal"
            overlayClassName="overlay"
            shouldCloseOnOverlayClick={true}
        >
            <div className="register-container">
                <form className="ticket-form" onSubmit={handleCreateTicket}>
                    <h2 className="title mt-0">Nouveau ticket</h2>

                    {/* Department Selection */}
                    <div className={"display-grid"}>
                        <div className={"display-flex justify-content-space-between"} style={{gap: "50px"}}>

                            <div className="input-container mb-0" style={{width: "calc(50% - 25px)"}}>
                                <div className="input-icon" style={{padding: "0 10px 0 20px"}}>
                                    <i className="fas fa-building color-third"></i>
                                </div>
                                <select
                                    style={{flexWrap: "wrap", alignContent: "center"}}
                                    className="input input-ticket my-10"
                                    value={department}
                                    onChange={(e) => {
                                        setDepartment(e.target.value);
                                    }}
                                    required
                                >
                                    <option value="" disabled>
                                        Choisir un département
                                    </option>
                                    {departments.map((departementOption) => (
                                        <option key={departementOption.id} value={departementOption.id}>
                                            {departementOption.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                                <div className="input-container mb-0" style={{width: "calc(50% - 25px)"}}>
                                    <div className="input-icon" style={{padding: "0 10px 0 20px"}}>
                                        <i className="fas fa-info-circle color-third"></i>
                                    </div>
                                    <select
                                        className="input input-ticket"
                                        value={ticketType}
                                        onChange={(e) => setTicketType(e.target.value)}
                                        required
                                    >
                                        <option value="" disabled>
                                            Type de ticket
                                        </option>
                                        <option value="select" selected={true} hidden>Type de ticket</option>
                                        {
                                            ticketTypes.map((type) => (
                                                <option value={type.id}>{type.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                        </div>
                        <div className={"display-flex justify-content-space-between gap-10"}>
                            {/* clients  */}
                            {ticketType && (
                                <div className="input-container mb-0" style={{width: "calc(50% - 25px"}}>
                                    <div className="input-icon" style={{padding: "0 10px 0 20px"}}>
                                        <i className="fa-solid fa-user color-third"></i>
                                    </div>
                                    <select
                                        style={{ flexWrap: "wrap", alignContent: "center" }}
                                        className="input input-ticket my-10"
                                        value={customer?.id || ""} // Use the ID of the selected customer
                                        onChange={(e) => {
                                            const selectedCustomerId = e.target.value;
                                            const selectedCustomer = customers.find(
                                                (client) => client.id === parseInt(selectedCustomerId)
                                            );
                                            setCustomer(selectedCustomer); // Update the selected customer
                                        }}
                                    >
                                        <option value="">Client (facultatif)</option>
                                        {customers.map((client) => (
                                            <option key={client.id} value={client.id}>
                                                {client.name_1}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            {ticketType && (<div className="input-container mb-0" style={{width: "calc(50% - 25px)"}}>
                                <div className="input-icon" style={{padding: "0 10px 0 20px"}}>
                                    <i className="fas fa-user color-third"></i>
                                </div>
                                <select
                                    style={{flexWrap: "wrap", alignContent: "center"}}
                                    className="input input-ticket my-10"
                                    value={selectedUser}
                                    onChange={(e) => setSelectedUser(e.target.value)}
                                    disabled={departementUsers.length === 0} // Disable if no users
                                >
                                    <option value="">Collaborateur (facultatif)</option>
                                    {departementUsers.map((user) => (<option key={user.id} value={user.id}>
                                        {user.username_display}
                                    </option>))}
                                </select>
                            </div>)}

                            {/* Ticket Title */}
                            {ticketType && (
                                <div className="input-container mb-0" style={{width: "calc(50% - 25px"}}>
                                    <div className="input-icon" style={{padding: "0 10px 0 20px"}}>
                                        <i className="fa-solid fa-light-emergency-on color-third"></i>
                                    </div>
                                    <select
                                        style={{flexWrap: "wrap", alignContent: "center"}}
                                        className="input input-ticket my-10"
                                        value={emergencyLevel}
                                    onChange={(e) => setEmergencyLevel(e.target.value)}
                                    required
                                >
                                    <option value="" disabled>Priorité</option>
                                    {priorityLevels.map((lvl) => (<option key={lvl.id} value={lvl.id}>
                                        {lvl.name}
                                    </option>))}
                                </select>
                            </div>)}
                        </div>

                        {ticketType && <div className="input-container mb-0  mb-20" style={{}}>
                            <div className="input-icon" style={{padding: "0 10px 0 20px", display: "none"}}>
                                <i className="fa-solid fa-heading color-third"></i>
                            </div>
                            <input
                                style={{fontSize: "1rem", marginBottom: 15, display: "none"}}
                                type="text"
                                className="input input-ticket my-10"
                                placeholder="Titre du ticket"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}

                            />
                        </div>}


                    </div>

                    {ticketType && <div className="input-container mb-0 ticket-description-container">
                        <div className="input-icon" style={{padding: "0 10px 0 20px"}}>
                            <i className="fa-solid fa-text color-third mt-10"/></div>
                        <ReactQuill
                            style={{width: "100%"}}
                            value={description}
                            onChange={(content) => setDescription(content)}
                            modules={quillModules}
                            formats={quillFormats}
                            placeholder="Décrivez le ticket (ajoutez du texte, des images, etc.)"
                        />
                    </div>}

                    {/* Buttons */}
                    <div className="display-flex justify-content-end gap-10 btn-container mt-15">
                        <button
                            className="btn-submit-account cancel"
                            style={{backgroundColor: "#ce3b2a"}}
                            onClick={closeModal}
                        >
                            Annuler
                        </button>
                        <button className="btn-submit-account" type="submit">
                            Créer le ticket
                        </button>
                    </div>
                </form>
            </div>
        </Modal>

    </div>);

};

export default TicketModal;
