import React, {useEffect, useState} from 'react';
import {NavLink, useParams} from "react-router-dom";
import {API_BASE_URL} from "../config";
import axios from "axios";
import ReactQuill from "react-quill";
import BackToTickets from "../components/Ticketing/BackToTickets";
import CommentsSection from "../components/Ticketing/CommentsSection";

// Add the Quill modules configuration
const quillModules = {
    toolbar: [[{header: [1, 2, 3, false]}], ['bold', 'italic', 'underline', 'strike'], // Text styling
        [{list: 'ordered'}, {list: 'bullet'}], ['blockquote', 'code-block'], ['link', 'image'], // Links and images
        [{align: []}], [{color: []}, {background: []}], // Color options
        ['clean'], // Remove formatting
    ], clipboard: {
        matchVisual: false,
    }, // Enable image resizing
    imageResize: {
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
};

// Formats allowed in the editor
const quillFormats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'blockquote', 'code-block', 'link', 'image', 'align', 'color', 'background',];

const Ticket = () => {

    const [hoveredUser, setHoveredUser] = useState(null);
    const {id} = useParams();
    const [ticket, setTicket] = useState([null]);
    const [description, setDescription] = useState("");
    const [hover, setHover] = useState(false); // State to manage hover effect
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
    const [assignedUsers, setAssignedUsers] = useState([])
    const [users, setUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])
    const [inputValue, setInputValue] = useState(""); // This will store the input text
    const [assignedUserIds, setAssignedUserIds] = useState([]);
    const [priorityLevels, setPriorityLevels] = useState([])
    const [priorityLevel, setPriorityLevel] = useState("")
    const [statusLevel, setStatusLevel] = useState()
    const [statusLevels, setStatusLevels] = useState([])
    const [toggleHoverPriority, setToggleHoverPriority] = useState(false)
    const [toggleHoverStatus, setToggleHoverStatus] = useState(false)
    const [comment, setComment] = useState("")
    const [comments, setComments] = useState([])
    const [updates, setUpdates] = useState([])
    const [dropdownWidth, setDropdownWidth] = useState(0);
    const [replyTo, setReplyTo] = useState()

    const calculateWidth = () => {
        const priorityTicketElement = document.querySelector(".ticket-status");
        if (priorityTicketElement) {
            const width = priorityTicketElement.getBoundingClientRect().width - 20;
            setDropdownWidth(width);
        }
    };

    useEffect(() => {
        // Calculate width on initial render
        calculateWidth();

        // Add event listener for window resize
        const handleResize = () => calculateWidth();
        window.addEventListener("resize", handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [dropdownWidth]);

    // Infos du ticket
    useEffect(() => {
        // Fetch ticket info
        axios
            .post(`${API_BASE_URL}/get_ticket_info`, {id})
            .then((res) => {
                const ticketData = res.data[0];
                setTicket(ticketData);
                setStatusLevel(ticketData.status_name)
                setDescription(ticketData.description);
                const userIds = typeof ticketData.assigned_user === "string" ? ticketData.assigned_user.split(",").map((id) => id.trim()) : [ticketData.assigned_user];
                setAssignedUserIds(userIds); // Set IDs here
            })
            .catch((error) => console.error("Error fetching ticket:", error));
    }, [id, priorityLevel]); // Only depends on `id`

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        fetchUsers(value); // Fetch users based on the input
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && inputValue) {
            saveTicket(inputValue); // Save ticket when Enter is pressed
        }
    };

    const deleteAssignedUser = (username) => {
        axios
            .post(`${API_BASE_URL}/delete_ticket_assigned_user`, {id: id, username: username})
            .then((res) => {
                setAssignedUserIds(res.data.updatedUsers);
            })
            .catch((error) => console.error("Error fetching ticket:", error));
    }

    const toggleTicketDetailsModal = (type) => {
        if (type === 'priority') {
            setToggleHoverPriority((prev) => !prev)
            setToggleHoverStatus(false)
        }
        if (type === 'status') {
            setToggleHoverStatus((prev) => !prev)
            setToggleHoverPriority(false)
        }
    }

    const saveTicket = (userName) => {
        axios
            .post(`${API_BASE_URL}/save_ticket_info`, {id: id, usernames_assigned: userName})
            .then((res) => {
                const assignedIdUsersArray = typeof res.data[0].assigned_user === "string" ? res.data[0].assigned_user.split(",").map((id) => id.trim()) : [res.data[0].assigned_user];
                setAssignedUserIds(assignedIdUsersArray);

            })
            .catch((error) => console.error("Error fetching ticket:", error));

        // Your function to save the ticket with the new user info
        console.log("Ticket saved with new user:", userName);
        setIsModalOpen(false); // Close modal after saving
        setInputValue(""); // Clear the input
    };

    useEffect(() => {
        axios
            .post(`${API_BASE_URL}/get_ticket_comments`, {id})
            .then((res) => {
                setComments(res.data);
            })
            .catch((error) => console.error("Error fetching ticket:", error));
    }, [id]);

    const showAddUserModal = () => {
        setIsModalOpen(true); // Show the input field when the + icon is clicked
    };

    const hideAddUserModal = () => {
        setIsModalOpen(false); // Hide the input field when needed
    };
    const handleDescriptionChange = (value) => setDescription(value);

    // Simulating fetching users (replace with your actual fetch logic)
    const fetchUsers = async (query) => {
        try {
            const res = await axios.post(`${API_BASE_URL}/get_users`);
            setUsers(res.data); // Assume res.data is an array of user objects
        } catch (error) {
            console.error("Error fetching users:", error);
        }

        // Filter users based on the query (search by username)
        const filtered = users.filter((user) => user.username_display.toLowerCase().startsWith(query.toLowerCase()) // Search by username
        );
        setFilteredUsers(filtered);
    };

    const changePriorityLevel = (priority) => {
        setToggleHoverPriority(false)
        axios
            .post(`${API_BASE_URL}/update_ticket_priority_level`, {id: id, priorityLevel: priority})
            .then((res) => {
                setPriorityLevel(priority)
            })
    }
    const changeStatus = (status) => {

        setToggleHoverStatus(false)

        const user_id = localStorage.user_id

        axios
            .post(`${API_BASE_URL}/update_ticket_status`, {id: id, status: status, user_id: user_id})
            .then((res) => {
                setStatusLevel(res.data[0].name)
            })
    }

    const saveComment = () => {
        const user_id = localStorage.user_id;
        axios
            .post(`${API_BASE_URL}/save_comment_ticket`, {id: id, user: user_id, comment: comment, reply_to: replyTo})
            .then((res) => {
                setComments(res.data) // refresh des commentaires pour afficher le nouveau
                setComment(null) // reset du formulaire de commentaire
            })
    }

    // Priorité ticket
    useEffect(() => {
        axios
            .post(`${API_BASE_URL}/get_priority_levels`, {id})
            .then((res) => {
                setPriorityLevels(res.data);
            })
            .catch((error) => console.error("Error fetching ticket:", error));
    }, [id, priorityLevel]);

    // Statut ticket
    useEffect(() => {
        axios
            .post(`${API_BASE_URL}/get_status_levels`, {id})
            .then((res) => {
                setStatusLevels(res.data);
            })
            .catch((error) => console.error("Error fetching ticket:", error));
    }, [id, statusLevel]);
    // Statut ticket
    useEffect(() => {
        axios
            .post(`${API_BASE_URL}/get_ticket_updates`, {id})
            .then((res) => {
                setUpdates(res.data);
            })
            .catch((error) => console.error("Error fetching ticket:", error));
    }, [id, statusLevel]);

    const translateIdsInNames = (ids) => {
        if (ids) {
            setAssignedUserIds(ids)
        }
    }

    useEffect(() => {
        translateIdsInNames();

        // Ensure `userIds` is always an array
        let userIds = Array.isArray(assignedUserIds) ? assignedUserIds : typeof assignedUserIds === 'string' ? assignedUserIds.split(',').map((id) => id.trim()) : [assignedUserIds];

        if (userIds.length > 0) {
            // Fetch usernames for the IDs
            axios
                .post(`${API_BASE_URL}/get_user_name`, {ids: userIds})
                .then((userRes) => {
                    setAssignedUsers(userRes.data.usernames);
                })
                .catch((error) => console.error("Error fetching usernames:", error));
        }
    }, [assignedUserIds]);

    const handleReply = (comment) => {
        setReplyTo(comment.id)
        setComment(null);
    };

    return (<div className="ticket-page">
        <div className="ticket-content">
            <div className="ticket-main">
                <div className={"container-back-ticket mb-20"}>
                    <BackToTickets/>
                </div>
                <h1 className="ticket-title">{ticket.title || "Untitled Ticket"}</h1>
                <div className="ticket-description">
                    <h2>Description</h2>

                    <ReactQuill
                        value={description}
                        onChange={handleDescriptionChange}
                        theme="snow"
                        modules={quillModules}
                        formats={quillFormats}
                        readOnly={true}
                    />

                </div>
                <div className="ticket-resolution-notes">
                    <h2>Commentaires</h2>
                    <div style={{paddingBottom: 30, display: "flex", flexDirection: "column"}}>

                        <CommentsSection comments={comments} onReply={handleReply}/>

                        <span className={"my-20"}>Ajouter un commentaire...</span>

                        <ReactQuill
                            value={comment}
                            readOnly={false}
                            style={{minHeight: 200, marginBottom: 30}}
                            modules={quillModules}
                            formats={quillFormats}
                            theme="snow"
                            onChange={setComment} // Update the state when the editor content changes
                            art
                        />

                        <button style={{zIndex: 9, marginLeft: 0}} className={"mt-20 button-third"}
                                onClick={saveComment}>Envoyer
                        </button>
                    </div>
                </div>
            </div>


            {/* Right Column */}
            <div className="ticket-meta">
                <div className="p-20 ticket-details">
                    <h3>Détails</h3>
                    {/* Priority and Status */}
                    <div className={"display-flex gap-10"}>
                        <div
                            className="flex-1"
                            onMouseEnter={() => setToggleHoverPriority(true)} // Show dropdown on hover
                            onMouseLeave={() => setToggleHoverPriority(false)} // Hide dropdown on hover out
                        >
                            {/* Current Priority Level */}
                            <span
                                style={{
                                    backgroundColor: ticket.priority_level,
                                    border: `solid 2px ${ticket.color}`,
                                    transition: "all 0.3s ease",
                                }}
                                className="priority-ticket ticket-status"
                            >
                <strong>{ticket.priority_level}</strong>
            </span>

                            {/* Hidden Priority Levels */}
                            {toggleHoverPriority && (
                                    <div
                                        className={`ticket-status-child display-flex`}
                                        style={{
                                            width: dropdownWidth, // Match width of parent
                                            transition: "opacity 0.3s ease, transform 0.3s ease",
                                            transform: toggleHoverPriority ? "translateY(0)" : "translateY(-10px)", // Slide-in effect
                                        }}
                                    >
                                    {priorityLevels.map((priority) => (
                                        <span
                                            key={priority.id}
                                            style={{
                                                border: `solid 2px ${priority.color}`,
                                                backgroundColor: `${priority.color}`,
                                                transition: "all 0.3s ease"
                                        }}
                                            className="priority-ticket ticket-status crop-text"
                                            onClick={() => changePriorityLevel(priority.id)}
                                        >
                            {priority.name}
                        </span>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div
                            className="flex-1"
                            onMouseEnter={() => setToggleHoverStatus(true)} // Show on hover
                            onMouseLeave={() => setToggleHoverStatus(false)} // Hide on hover out
                        >
                            {/* Current Status */}
                            <span
                                className="priority-ticket ticket-status crop-text"
                                style={{
                                    border: `solid 2px ${ticket.status_color}`,
                                    transition: "all 0.3s ease",
                                                                   }}
                            >
                <strong>{statusLevel}</strong>
            </span>

                            {/* Hidden Status Levels */}
                            {toggleHoverStatus && (
                                <div
                                    className={`ticket-status-child display-flex`}
                                    style={{
                                        width: dropdownWidth, // Match width of parent
                                        transition: "opacity 0.3s ease, transform 0.3s ease",
                                        opacity: toggleHoverStatus ? 1 : 0, // Fade in/out
                                    }}
                                >
                                    {statusLevels.map((status) => (
                                        <span
                                            key={status.id}
                                            style={{
                                                border: `solid 2px ${status.color}`,
                                                backgroundColor: `${status.color}`,
                                                transition: "background-color 0.3s ease, transform 0.2s ease",
                                            }}
                                            className="priority-ticket ticket-status crop-text"
                                            onClick={() => changeStatus(status.id)}
                                        >
                            {status.name}
                        </span>
                                    ))}
                                </div>
                            )}
                        </div>

                    </div>


                    <p className={"mt-15"}><strong>
                        Ouvert le</strong> {new Date(ticket.created_at).toLocaleString()} par <strong>{ticket.created_username}</strong>
                    </p>
                    {ticket.name_1 &&
                        <p className={"mt-15 display-flex"}>
                            <NavLink target={"_blank"} to={`/client/${ticket.customer}`}
                                     className={"display-flex text-decoration-none"}>
                                <i class="fa-solid fa-up-right-from-square"></i> <strong>&nbsp;&nbsp;{ticket.name_1}</strong>
                            </NavLink>
                        </p>}
                </div>

                <div className="p-20 p-20 ticket-details">
                    <p>
                        <strong>Personne(s) assignée(s):</strong>{" "}
                        <div className={"display-grid"}>
                            <span
                                className="ticket-icon-people-container"
                                onMouseLeave={() => setHoveredUser(null)} // Clear hover state on mouse leave
                            >
                                {assignedUsers.map((username_display, index) => (<span
                                    key={index}
                                    className="ticket-icon-people"
                                    onMouseEnter={() => setHoveredUser(username_display)} // Set hovered user
                                >
                                {username_display}

                                    {hoveredUser === username_display && ( // Show delete icon only for the hovered user
                                        <span
                                            className="delete-user-icon"
                                            onClick={() => deleteAssignedUser(username_display)}
                                            style={{cursor: "pointer", marginLeft: "10px"}}
                                        >
                                            <i className="fa-solid fa-xmark"></i>
                                        </span>)}
                                </span>))}
                                <span
                                    className="add-user-icon ticket-icon-people"
                                    onClick={showAddUserModal}
                                    style={{cursor: "pointer"}}
                                >
                                    <i className="fa-solid fa-plus"></i>
                                </span>
                            </span>

                            {isModalOpen && (<div style={{marginBottom: "20px"}}>
                                <input
                                    autoFocus
                                    type="text"
                                    className={"no-outline border-bottom-1px-third"}
                                    placeholder="Ajouter..."
                                    value={inputValue}
                                    onChange={handleInputChange} // Update input value
                                    onKeyDown={handleKeyDown} // Handle Enter key press
                                    onBlur={hideAddUserModal} // Hide input on losing focus
                                    style={{padding: "5px", fontSize: "14px"}}
                                />
                                {filteredUsers.length > 0 && (
                                    <ul style={{marginTop: 5, paddingLeft: 0, listStyleType: "none"}}>
                                        {filteredUsers.map((user, index) => (<li
                                            key={index}
                                            onClick={() => saveTicket(user)} // Save on user click
                                            style={{cursor: "pointer", padding: "5px"}}
                                        >
                                            {user.username_display}
                                        </li>))}
                                    </ul>)}
                            </div>)}
                        </div>
                        <strong>Dernière mise à jour:</strong> {new Date(ticket.updated_at).toLocaleString()}

                    </p>
                </div>

                <div className="p-20 ">
                    <div className={"display-flex flex-direction-column gap-10"}>
                        <strong>Historique</strong>
                        {updates.map((update) => (
                            <p className={"ticket-status-historic-container display-flex gap-20 flexwrap-wrap justify-content-space-between align-items-baseline"}>
                            <span
                                className={"ticket-status-historic-span flex-2 display-flex justify-content-space-between align-items-baseline"}>
                                <strong
                                    className={"p-5-10 display-flex justify-content-center flex-2 border-radius-round border-solid-2px crop-text text-center"}
                                    style={{borderColor: `${update.past_status_color}`}}>
                                {update.past_status_name}
                                </strong>
                                <span
                                    className={"m-10 display-flex justify-content-center flex-1 ticket-status-historic-icon"}>

                            <i className="fa-solid fa-right color-third "></i>
                                </span>
                            <strong
                                className={"p-5-10 display-flex justify-content-center flex-2 border-radius-round border-solid-2px crop-text text-center"}
                                style={{borderColor: `${update.new_status_color}`}}>
                                {update.new_status_name}</strong>
                            </span>

                                <span className={"flex-1"} style={{fontSize: ".8rem"}}>
                                Le <strong>{new Date(update.date).toLocaleString()}</strong> par <strong>{update.updated_by}</strong>
                            </span>
                            </p>))}

                    </div>
                </div>

            </div>
        </div>

        {/* Add User Modal */}
    </div>);
};

export default Ticket;