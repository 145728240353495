import React, { useEffect, useState } from "react";

const PositionTimingInfo = ({ data }) => {
    const [totalTimeSpentColor, setTotalTimeSpentColor] = useState("");
    const [timeSpentDifference, setTimeSpentDifference] = useState("");

    useEffect(() => {
        const parseTimeToMinutes = (time) => {
            const [hours, minutes] = time.split(":").map(Number);
            return hours * 60 + minutes; // Convert to total minutes
        };

        const estimatedMinutes = parseTimeToMinutes(data.totalEstimatedPerTasksFinished);
        const spentMinutes = parseTimeToMinutes(data.totalTimeSpent);

        const difference = spentMinutes - estimatedMinutes; // Calculate the difference
        setTimeSpentDifference(
            difference > 0
                ? `+${difference} min` // Exceeded planned time
                : `${difference} min` // Within planned time
        );

        // Set color: red if exceeded, otherwise black
        setTotalTimeSpentColor(difference > 0 ? "red" : "black");
    }, [data.totalEstimatedPerTasksFinished, data.totalTimeSpent]);

    return (
        <div
            className={"p-15 flex bg-white border-radius-10 border-solid-1px no-radius-top no-border-top"}
            style={{ borderColor: "#ddd" }}
        >
            <div
                className={"positions-list w-100"}
                style={{ textDecoration: "none", color: "var(--third)", maxWidth: 347 }}
            >
                <div
                    className={
                        "p-10 mb-5 border-radius-5 w-100 display-flex flex-direction-column gap-10"
                    }
                >
                    <div className={"display-flex flex-direction-row gap-10"}>
                        <i
                            className="fa-regular fa-stopwatch"
                            style={{ marginRight: "5px" }}
                        />
                        <span style={{ fontSize: "1rem" }}>
                            Temps réel passé :{" "}
                            <strong style={{ color: totalTimeSpentColor }}>
                                {data.totalTimeSpent} ({timeSpentDifference})
                            </strong>
                        </span>
                    </div>

                    <div className={"display-flex flex-direction-row gap-10"}>
                        <i
                            className="fa-solid fa-hourglass-clock"
                            style={{ marginRight: "5px" }}
                        ></i>
                        <span style={{ fontSize: "1rem" }}>
                            Temps planifié passé :{" "}
                            <strong>{data.totalEstimatedPerTasksFinished}</strong>
                        </span>
                    </div>
                    <div className={"display-flex flex-direction-row gap-10"}>
                        <i
                            className="fa-regular fa-hourglass-half"
                            style={{ marginRight: "5px" }}
                        ></i>
                        <span style={{ fontSize: "1rem" }}>
                            Temps planifié restant :{" "}
                            <strong>{data.totalRemainingEstimatedTime}</strong>
                        </span>
                    </div>
                    <div className={"display-flex flex-direction-row gap-10"}>
                        <i
                            className="fa-regular fa-hourglass-end"
                            style={{ marginRight: "5px" }}
                        ></i>
                        <span style={{ fontSize: "1rem" }}>
                            Temps total planifié :{" "}
                            <strong>{data.totalEstimatedTime}</strong>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PositionTimingInfo;
