import React, {useEffect, useState, Fragment} from 'react';
import axios from "axios";
import {API_BASE_URL} from "../config";
import {Link, NavLink} from "react-router-dom";
import WIP from "./WIP";
import isEmpty from "lodash/isEmpty";
import Pagination from "./Pagination";

const Table = ({
                   maxEntries,
                   typeOfPosition,
                   data,
                   headersData,
                   hidePagination,
                   handleSort,
                   sortBy,
                   headers,
                   showActions,
                   title
               }) => {

    const [finished, setIsFinished] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(0);

    const MAX_ENTRIES_PAGINATION = maxEntries || 50;
    const MAX_DISPLAY_PAGES = 3; // Maximum number of pages to display in the pagination
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const [currentEntries, setCurrentEntries] = useState([]);
    const totalEntries = data.length;
    const totalPages = Math.ceil(totalEntries / entriesPerPage);

    const formatDate = (isoDate) => {
        const date = new Date(isoDate); // Create a Date object from the ISO string
        const day = String(date.getUTCDate()).padStart(2, '0'); // Get the day and pad with leading zero
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Get the month (0-11, hence +1) and pad
        const year = date.getUTCFullYear(); // Get the full year
        return `${day}/${month}/${year}`; // Return in the format DD/MM/YYYY
    };
    const formatTime = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`; // Format as HH:mm
    };
    useEffect(() => {
        if (Array.isArray(data) && !isEmpty(data)) {
            setCurrentEntries(data.slice(indexOfFirstEntry, indexOfLastEntry));
        }
    }, [data, indexOfFirstEntry, indexOfLastEntry]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

// Secure login handler
    const handleLogin = async (url, version) => {
        if (version > '3.0.0') {
            try {
                window.open('https://' + url + '/login?login=true&user=connexion@solution-digitale.ch&pass=ZL9YJH6BSI(PZxh2NyAzdzV@', '_blank');

            } catch (error) {
                console.error('Error logging in:', error);
            }
        } else {
            window.open('https://' + url + '/wp-admin', '_blank');
        }
    };

    // Generate pagination buttons
    const pagesToShow = Math.min(MAX_DISPLAY_PAGES, totalPages);
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    const getColorFromProgress = (progress) => {
        if (progress === 0) {
            return 'rgb(255, 255, 255)';  // Set color to white if progress is 0
        }

        let red, green;

        if (progress <= 50) {
            // Transition from red to yellow (50% progress)
            red = 255;
            green = Math.round(progress * 5.1);  // Green goes from 0 to 255
        } else {
            // Transition from yellow to green (50% to 100% progress)
            red = Math.round(255 - (progress - 50) * 5.1);  // Red goes from 255 to 0
            green = 255;
        }

        return `rgb(${red}, ${green}, 0)`;  // Blue stays at 0
    };


    const renderPages = () => {
        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            pages.push(<button key={i} onClick={() => handlePageChange(i)}
                               className={currentPage === i ? 'active' : ''}>{i}</button>);
        }
        return pages;
    };

    const handleAction = (action, id) => {
        if (action === 'deletePermanently') {
            const res = window.confirm("Êtes-vous sûr de vouloir supprimer définitivement ce projet ? Cette action est irréversible.");
            if (res) {
                axios.post(`${API_BASE_URL}/archive_position_definitively`, {id})
                    .then(response => {
                        if (response.data.status === 'ok') {
                            setCurrentEntries(prevEntries => prevEntries.filter(entry => entry.id !== id));
                        }
                    })
                    .catch(error => {
                        console.error('Error deleting position:', error);
                    });
            }
        }
        if (action === 'edit') {
            const url = `/positions/${id}`;
            window.open(url);
        }
        if (action === 'finish') {
            axios.post(`${API_BASE_URL}/finish_position`, {id})
                .then(response => {
                    if (response.data.status === 'ok') {
                        setCurrentEntries(prevEntries => prevEntries.filter(entry => entry.id !== id));
                    }
                })
                .catch(error => {
                    console.error('Error deleting position:', error);
                });
        }
        if (action === 'restore') {
            axios.post(`${API_BASE_URL}/restore_position`, {id})
                .then(response => {
                    if (response.data.status === 'ok') {
                        setCurrentEntries(prevEntries => prevEntries.filter(entry => entry.id !== id));
                    }
                })
                .catch(error => {
                    console.error('Error deleting position:', error);
                });
        }
        if (action === 'delete') {
            const res = window.confirm("Voulez-vous vraiment supprimer cette position ?");
            if (res) {
                axios.post(`${API_BASE_URL}/delete_position`, {id})
                    .then(response => {
                        if (response.data.status === 'ok') {
                            setCurrentEntries(prevEntries => prevEntries.filter(entry => entry.id !== id));
                        } else {
                            console.error('error');
                        }
                    })
                    .catch(error => {
                        console.error('Error deleting position:', error);
                    });
            }
        }
        if (action === 'delete_task') {
            const res = window.confirm("Voulez-vous vraiment supprimer cette tâche ?");
            if (res) {
                axios.post(`${API_BASE_URL}/delete_task`, {id})
                    .then(response => {
                        if (response.data.status === 'ok') {
                            setCurrentEntries(prevEntries => prevEntries.filter(entry => entry.id !== id));
                        } else {
                            console.error('error');
                        }
                    })
                    .catch(error => {
                        console.error('Error deleting task:', error);
                    });
            }
        }
    };

    useEffect(() => {
        if (hidePagination) {
            setEntriesPerPage(1000); // Adjust as needed
        } else {
            setEntriesPerPage(MAX_ENTRIES_PAGINATION);
        }
        if (typeOfPosition === 'finished') typeOfPosition = 'finished' ? setIsFinished(true) : setIsFinished(false);
    }, [typeOfPosition]);

    return (<Fragment>

        {/* Pagination */}
        {!hidePagination && (<div className="pagination">
            <button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                &lt;&lt;
            </button>
            {currentPage > 1 && (<button onClick={() => handlePageChange(currentPage - 1)}>&lt;</button>)}
            {renderPages()}
            {currentPage < totalPages && (<button onClick={() => handlePageChange(currentPage + 1)}>&gt;</button>)}
            <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>
                &gt;&gt;
            </button>
        </div>)}
        {/* End pagination */}
        {title && <h3>{title}</h3>}
        <div className={'tableFixHead'} style={{overflow: "auto"}}>
            <table className="table-search">
                <thead>
                <tr>
                    {Array.isArray(headers) && headers.map(el => (
                        <th key={el} className={`header-cell tr-${el}`} onClick={() => handleSort(el)}>
                            {el} {sortBy && sortBy.column === el && (sortBy.ascending ?
                            <span className="center-block">▲</span> : <span className="center-block">▼</span>)}
                        </th>))}
                </tr>
                </thead>
                <tbody>
                {Array.isArray(currentEntries) && currentEntries.map(item => (<tr key={item.id}>
                    {Array.isArray(headersData) && headersData.map(header => (
                        <td key={`${item.id}-${header}`} className="index-cell">
                            {header === 'date' ? <span>
    {new Date(parseInt(item.timestamp)).toLocaleDateString("fr-FR")}
  </span> : header === 'url' ? <Fragment>
                                    <NavLink to={'https://' + item.url} target="_blank">
                                        {item.url}
                                    </NavLink>

                                </Fragment>

                                : header === 'url_admin' ? (<button onClick={() => handleLogin(item.url, item.version)}
                                                                    className="login-btn cursor-pointer">
                                    <i className="fa-solid fa-user-shield"/>
                                </button>) : header === 'visu_url' ?
                                    <NavLink to={'https://visu.solution-digitale.ch/' + item.url}
                                             target="_blank">
                                                    <span className="text-left">
                                                        {item.url}
                                                    </span>
                                    </NavLink> : header === 'visu_url_admin' ? <NavLink
                                        to={'https://visu.solution-digitale.ch/' + item.url + '/wp-admin'}
                                        target="_blank">
                                        <i className="fa-solid fa-user-shield"/>
                                    </NavLink> : header === 'finished_at' ? (item[header] === "jj/mm/aaaa hh:mm" ?
                                            <span>Date non renseignée</span> : item[header])

                                        : header === 'username' ?
                                            <NavLink className="text-left" to={'/positions/' + item.position_id}
                                                     target="_blank">
                                                <span className={"color-black-600"}> {item.username} </span>
                                            </NavLink> : header === 'title' ?
                                                <NavLink target={"_blank"} className="text-left"
                                                         to={'/positions/' + item.position_id}>
                                                        <span className={"color-black-600"}
                                                              dangerouslySetInnerHTML={{__html: item.title}}/>
                                                </NavLink> : header === 'progress' ? <span className="round-button"
                                                                                           style={{backgroundColor: getColorFromProgress(item[header])}}>
                                                                <p className="t-0 d-ib">{parseInt(item[header], 10)}%</p>
                                                            </span> : header === "id_customer" ?
                                                    <NavLink className="text-left" to={'/positions/' + item.id}
                                                             target="_blank">
                                                                <span className={"color-black-600"}
                                                                      dangerouslySetInnerHTML={{__html: item.id}}/>
                                                    </NavLink> : header === "avg_time_spent" ?

                                                        <span>
                                                                    {formatTime(item.avg_time_spent)}</span> : header === "position_total" ?

                                                            <span>
    {new Intl.NumberFormat('de-CH', {style: 'currency', currency: 'CHF'}).format(item['position_total'])}
  </span> : header === 'avg_price_per_time_spent' ? <span>
    <p className="t-0 d-ib">
        {(() => {
            // Extract hours, minutes, and seconds from avg_time_spent
            const [hours, minutes, seconds] = item['avg_time_spent'].split(':').map(Number);

            // Convert avg_time_spent to decimal hours
            const totalHours = hours + minutes / 60 + seconds / 3600;

            // Calculate hourly price if totalHours is greater than 0
            const hourlyPrice = totalHours > 0 ? (item['position_total'] / totalHours).toFixed(2) : 'N/A';

            return new Intl.NumberFormat('de-CH', {style: 'currency', currency: 'CHF'}).format(hourlyPrice);
        })()}
    </p>
</span> : header === "id_customer" ? <NavLink className="text-left"
                                              to={'/positions/' + item.id}
                                              target="_blank">
                                                            <span className={"color-black-600"}
                                                                  dangerouslySetInnerHTML={{__html: item.id}}/>
                                                            </NavLink> : header === "title_customer" ?
                                                                <NavLink className="text-left"
                                                                         to={'/positions/' + item.id}
                                                                         target="_blank">
                                                                    <div className={"display-flex gap-10 display-flex align-items-center"}>
                                                                        {item.is_archived === 1 && <span
                                                                            className={"archived-span"}>Archivé</span>}
                                                                        {item.progress === 100 && <span
                                                                            className={"finished-span"}>Terminé</span>}
                                                                        <div
                                                                            className={"display-flex align-items-center gap-10"}>
                                                                 <span className={"color-black-600"}
                                                                       dangerouslySetInnerHTML={{__html: item.title}}/>

                                                                        </div>

                                                                    </div>
                                                                </NavLink> : header === 'progress' ?
                                                                    <span className="round-button"
                                                                          style={{backgroundColor: getColorFromProgress(item[header])}}>
                                                                <p className="t-0 d-ib">{parseInt(item[header], 10)}%</p>
                                                            </span> : header === 'Secteur' ? <span>
                                                                    <WIP/>
                                                                </span> : header === 'role' ?
                                                                        <span>
                                                                                    <a
                                                                                        className="text-decoration-none text-left"
                                                                                        target="" rel="noreferrer"
                                                                                        dangerouslySetInnerHTML={{__html: item.role}}/>


                                                                            </span>: header === 'users' ?
                                                                            <span>
                                                                                    <a
                                                                                        className="text-decoration-none text-left"
                                                                                        target="" rel="noreferrer"
                                                                                        dangerouslySetInnerHTML={{__html: item.username}}/>


                                                                            </span>: header === 'department' ?
                                                                            <span>
                                                                                    <a
                                                                                        className="text-decoration-none text-left"
                                                                                        target="" rel="noreferrer"
                                                                                        dangerouslySetInnerHTML={{__html: item.department_names}}/>
                                                                                {console.log(item)}


                                                                            </span> : header === 'email' ?
                                                                                <span>
                                                                                    <a
                                                                                        className="text-decoration-none text-left"
                                                                                        target="" rel="noreferrer"
                                                                                        dangerouslySetInnerHTML={{__html: item.email}}
                                                                                    />
                                                                            </span>: header === 'order_id' ? <span>
                                                                        <Link style={{
                                                                            display: "flex",
                                                                            gap: "5px",
                                                                            alignItems: "center",
                                                                        }}
                                                                              to={`https://office.bexio.com/index.php/kb_order/show/id/${item['order_id']}`}
                                                                              target="_blank" rel="noreferrer"
                                                                              className="text-decoration-none">

                                                                            <img alt="Logo Bexio" height={20}
                                                                                 src={'https://x.solution-digitale.ch/assets/bexio-ag-logo-vector.png'}/>
                                                                            <span style={{
                                                                                fontSize: ".9rem",
                                                                                color: "var(--primary)",
                                                                                fontWeight: 700,
                                                                                fontStyle: "italic"
                                                                            }}>({item.order_id})</span>
                                                                        </Link>
                                                                    </span> : header === 'position_id' ? <span>
                                                                            <NavLink
                                                                                to={`https://office.bexio.com/index.php/kb_order/show/id/${item['position_id']}`}
                                                                                target="_blank" rel="noreferrer">
                                                                                <p>{item[header]}
                                                                                    <i className="fa fa-external-link pl-10"
                                                                                       aria-hidden="true"/>
                                                                                </p>
                                                                            </NavLink>
                                                                        </span> : header === 'contact_id' ?
                                                                        <span style={{maxWidth: 100}}>
                                                                        <NavLink
                                                                            to={item.customer && item.customer.datas && item.customer.datas[0] ? `/client/${item.customer.datas[0].contact_id}` : '#'}
                                                                            target="_blank" rel="noreferrer"
                                                                            style={{textDecoration: "none"}}>
   <p>
    {item[header]}
       {item.customer && item.customer.datas && item.customer.datas[0] && (<a style={{textDecoration: "none"}}>
           {item.customer && item.customer.datas && item.customer.datas[0] && (`${item.customer.datas[0].name_1} ${item.customer.datas[0].name_2 ? item.customer.datas[0].name_2 : ''}`)}           </a>)}
</p>
</NavLink>

                                                                        </span> : header === 'project_count' || header === 'city' ?
                                                                            <span>
                                                                                <NavLink to={`/client/${item['id']}`}
                                                                                         className="text-decoration-none text-left"
                                                                                         target="" rel="noreferrer">
                                                                                    <p>
                                                                                        {item[header]}
                                                                                    </p>
                                                                                </NavLink>

                                                                            </span> : header === 'position_reporting' ?
                                                                                <span>
                                                                                    <NavLink
                                                                                        to={`/positions/${item['position_id']}`}
                                                                                        className="text-decoration-none text-left"
                                                                                        target="" rel="noreferrer"
                                                                                        dangerouslySetInnerHTML={{__html: item.title}}/>


                                                                            </span> : header === 'version' || header === 'creation_date' || header === 'server' ?
                                                                                    <span>
                                                                                    <p>
                                                                                        {item[header]}
                                                                                    </p>

                                                                            </span> : header === 'name_1' || header === 'name_2' ?
                                                                                        <span
                                                                                            id={item.id}
                                                                                        >
                                                                            <NavLink to={`/client/${item.contact_id}`}
                                                                                     className="text-decoration-none text-left"
                                                                                     target=""
                                                                                     rel="noreferrer"
                                                                            >
                                                                              <p>{item[header]}</p>
                                                                            </NavLink>
                                                                          </span> : header === 'name_1_reporting' ?
                                                                                            <span>
                                                                            <NavLink to={`/client/${item.contact_id}`}
                                                                                     className="text-decoration-none text-left"
                                                                                     target="_blank"
                                                                                     rel="noreferrer"
                                                                            >
                                                                              <p>{item['name_1']}</p>
                                                                            </NavLink>
                                                                          </span> : header === 'branch_names' ? <span
                                                                                                className="info-branch">
                                                                                        {item[header]}
                                                                                    </span> : header === 'delete_task' ?
                                                                                                <span
                                                                                                    className="info-branch">
                                                                                         <i style={{
                                                                                             color: "yellowgreen",
                                                                                             fontSize: "21px"
                                                                                         }}
                                                                                            className="cursor-pointer position-icon fa-solid position-icon fa-trash icon-trash-restore"
                                                                                            onClick={() => handleAction('delete_task', item.id)}></i>
                                                                                    </span> : header === 'customer_name' ?
                                                                                                    <span
                                                                                                        className="info-branch">
                                                                                            <NavLink
                                                                                                to={`/client/${item['id']}`}
                                                                                                className="text-decoration-none text-left"
                                                                                                target=""
                                                                                                rel="noreferrer">
                                                                                                <p>
                                                                                                    {item['name_1']}
                                                                                                </p>
                                                                                            </NavLink>
                                                                                        </span> : header === 'sended' ?
                                                                                                        <span>
                                                                                                {item[header] === 'Envoyé' ?
                                                                                                    <p>Envoyée</p> : <p>
                                                                                                        <i className="fa-solid fa-triangle-exclamation color-orangered"/>&nbsp;Pas
                                                                                                        envoyée
                                                                                                    </p>}
                                                                                            </span> : header === 'position_id_reporting' ?
                                                                                                            <span>
                                                                                <NavLink
                                                                                    to={`/positions/${item['position_id']}`}
                                                                                    className="text-decoration-none text-left"
                                                                                    target="_blank" rel="noreferrer">
                                                                                    <p>
                                                                                        {item['position_id']}
                                                                                    </p>
                                                                                </NavLink>

                                                                            </span> : header === 'avis' ? <span
                                                                                                                className={"text-center display-block"}>
                                                                                            {item['first_avis_sent_date'] !== null ?
                                                                                                <i className="fa-solid fa-check p-10"
                                                                                                   style={{color: "mediumseagreen"}}/> :
                                                                                                <i
                                                                                                    className="fa-solid fa-xmark p-10"
                                                                                                    style={{color: "orangered"}}/>}
                                                                                                                {item['avis'] !== 0 ?
                                                                                                                    <i className="fa-solid fa-check p-10"
                                                                                                                       style={{color: "mediumseagreen"}}/> :
                                                                                                                    <i
                                                                                                                        className="fa-solid fa-xmark p-10"
                                                                                                                        style={{color: "orangered"}}/>}

                                                                                            </span> : header === 'order_start_date' || header === 'finish_date' || header === 'deletion_date' || 'end_date' ?
                                                                                                                <span>
                                                                                                    {`${new Date(item[header]).getDate()}/${new Date(item[header]).getMonth() + 1}/${new Date(item[header]).getFullYear()}`}
                                                                                                </span> : data === 'position_id' ?
                                                                                                                    <span
                                                                                                                        onClick={() => handleAction('edit', item.position_id)}>
                                                                                                        <p dangerouslySetInnerHTML={{__html: item[header]}}></p>
                                                                                                    </span> : <span
                                                                                                                        onClick={() => handleAction('edit', item.position_id)}>
                                                                                                        <p dangerouslySetInnerHTML={{__html: item[header]}}></p>
                                                                                                    </span>}
                        </td>))}
                    {showActions && (<td className="td-cell">
                        <div className="position-icon-container">
                            {item.progress === '100' && !item.is_archived ? (
                                <i className="fa-solid position-icon fa-eye icon-eye"
                                   onClick={() => handleAction('edit', item.position_id)}/>) : null}
                            {localStorage.user_role === "Administrateur" && (<>
                                {item.is_archived ? (<>
                                    <i style={{color: "yellowgreen", fontSize: "21px"}}
                                       className="cursor-pointer position-icon fa-solid position-icon fa-trash-restore icon-trash-restore"
                                       onClick={() => handleAction('restore', item.id)}></i>
                                    <i className="fa-solid position-icon fa-trash icon-trash"
                                       onClick={() => handleAction('deletePermanently', item.id)}></i>
                                </>) : (<i className="fa-solid position-icon fa-trash icon-trash"
                                           onClick={() => handleAction('delete', item.id)}></i>)}
                            </>)}
                        </div>
                    </td>)}
                </tr>))}
                </tbody>
            </table>
        </div>

        {!hidePagination && (<div className="pagination" style={{borderRadius: "0 0 5px 5px"}}>
            <button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                &lt;&lt;
            </button>
            {currentPage > 1 && (<button onClick={() => handlePageChange(currentPage - 1)}>&lt;</button>)}
            {renderPages()}
            {currentPage < totalPages && (<button onClick={() => handlePageChange(currentPage + 1)}>&gt;</button>)}
            <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>
                &gt;&gt;
            </button>
        </div>)}
        {/* End pagination */}
    </Fragment>);
};

export default Table;
