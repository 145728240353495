import React, {useEffect, useState} from 'react';
import TicketsTable from "../components/Ticketing/TicketsTable";
import axios from "axios";
import {API_BASE_URL} from "../config";
import {NavLink} from "react-router-dom";
import BackToTickets from "../components/Ticketing/BackToTickets";
import TicketsSideBar from "../components/Ticketing/TicketsSideBar";
import TicketModal from "../components/Ticketing/TicketModal";

const MesTicketsAssignes = () => {

    const [userTickets, setUserTickets] = useState([])
    const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
    const [fetchTicketsTrigger, setFetchTicketsTrigger] = useState(false); // Trigger for fetching tickets

    const toggleTicketModal = (state, status) => {
        setIsTicketModalOpen((ticketModal) => !ticketModal);

        // Trigger a refetch when a ticket is successfully created
        if (status === "saved") {
            setFetchTicketsTrigger((prev) => !prev);
        }
    };
    useEffect(() => {
        const user_id = localStorage.user_id;
        axios
            .post(`${API_BASE_URL}/get_user_tickets_assigned`, {id: user_id})
            .then((res) => {
                console.log(res.data)
                setUserTickets(res.data);
            })
            .catch((error) => console.error("Error fetching ticket:", error));
    }, []);

    return (
        <div>

            <h2>Mes tickets assignés</h2>
            <div className={"content"}>

                <div className={"container-back-ticket"}>
                    <BackToTickets/>
                </div>

                <div className={"display-flex"}>
                    <TicketsSideBar toggleTicketModal={toggleTicketModal}/>
                    <TicketsTable tickets={userTickets}/>
                </div>
                {isTicketModalOpen && (
                    <TicketModal closeModal={(status) => toggleTicketModal(false, status)}/>
                )}

            </div>
        </div>
    );
};

export default MesTicketsAssignes;