import React, {Fragment, useEffect} from 'react';
import LastTasks from "../components/LastTasks";
import axios from "axios";
import {API_BASE_URL} from "../config";
import WIP from "../components/WIP";
import {Link} from "react-router-dom";

const Home = () => {

    useEffect(() => {
        document.title = 'X | Accueil'
    }, []);
    return (<Fragment>

        <div style={{
            height: "100%",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            padding: 0
        }}>
            <div className={'justify-content-center display-flex'} ><img
                src={'https://x.solution-digitale.ch/assets/logo-solution-digitale-250.png'} height={100}
                alt={'Logo Solution Digitale'}/>
            </div>
            <h2 className={'m-50'}>Actions rapides</h2>
            <div className={'display-flex p-20'}>
                <div className={'card-wrapper p-10 flex-1'}>
                    <div className={"card"}>
                        <div className={'pb-10'}>
                            <h3 className={'color-white m-0'}>Mes dernières tâches</h3>
                        </div>
                        <hr />
                        <LastTasks number={5}/>
                    </div>
                </div>
                <div className={'card-wrapper p-10 flex-1'} >
                    <div className={"card"}>
                        <div className={'pb-10'} >
                            <h3 className={'color-white m-0'}>Mon compte</h3>
                        </div>
                        <hr />
                        <Link to={"/account"} className={'m-50 text-decoration-none'}><h4>Mettre à jour mon mot de passe</h4></Link>
                    </div>
                </div>
            </div>
        </div>

    </Fragment>)

};

export default Home;