import React from 'react';
import {NavLink} from "react-router-dom";

const BackToTickets = () => {
    return (
        <div>

            <NavLink to={"/tickets"} className={"display-flex text-decoration-none mb-20"} style={{width: "fit-content"}}>
                    <span className={"back-button back-ticket position-relative cursor-pointer"}><i
                        className="fa-solid fa-angle-left"></i>Retour aux tickets</span>
            </NavLink>
        </div>
    );
};

export default BackToTickets;