import React, {Fragment} from 'react';

const ExportSaveButtons = () => {


    const reloadPage = () =>{
        window.location.reload()
    }

    return (
        <Fragment>
            <div style={{padding: "15px 0 15px 0"}}>
                <button className={"button-action-save m-0"}
                        onClick={() => reloadPage()}
                        style={{padding: "10px 15px", cursor: "pointer"}}>
                    <i className="fa-regular fa-floppy-disk"
                    style={{marginRight: "5px"}}></i>Sauvegarder
                </button>
            </div>
        </Fragment>
    );
};

export default ExportSaveButtons;