import {API_BASE_URL} from "./config";
import React, {useEffect, useState, Fragment} from "react";
import {Routes, Route} from 'react-router-dom';
import axios from "axios";
import './assets/css/main.css';
import './assets/fonts/fonts.css';
import './App.css';
import Navbar from "./components/Navbar";
import PositionsEnCours from "./pages/PositionsEnCours";
import PositionsFinished from "./pages/PositionsFinished";
import PositionDetail from "./pages/PositionDetail";
import Seo from "./pages/Seo";
import QRCodes from "./pages/QRCodes";
import Login from "./pages/Login";
import SeoChecker from "./pages/SeoChecker";
import Emails from "./pages/Emails";
import DnsLookup from "./pages/DnsLookup";
import DnsPropagation from "./pages/DnsPropagation";
import PatchXgrid from "./pages/PatchXgrid";
import SitesPublies from "./pages/SitesPublies";
import SitesVisu from "./pages/SitesVisu";
import ListeTaches from "./pages/ListeTaches";
import Clients from "./pages/Clients";
import Commandes from "./pages/Commandes";
import Calendrier from "./pages/Calendrier";
import Produits from "./pages/Produits";
import CreationTaches from "./pages/CreationTaches";
import Register from "./pages/Register";
import MainContent from "./components/MainContent";
import Logout from "./pages/Logout";
import ListeToutesTaches from "./pages/ListeToutesTaches";
import Reporting from "./pages/Reporting";
import Home from "./pages/Home";
import Client from "./pages/Client";
import Account from "./pages/Account";
import InfoBar from "./components/InfoBar";
import PositionsDeleted from "./pages/PositionsDeleted";
import Trello from "./pages/Trello";
import PositionsSuivi from "./pages/PositionsSuivi";
import Renouvellements from "./pages/Renouvellements";
import Productivity from "./pages/Productivity";
import AuthProvider from "./contexts/AuthProvider";
import BexioUpdate from "./pages/BexioUpdate";
import MainSearchBar from "./components/MainSearchBar";
import ResponsiveNavbar from "./components/ResponsiveNavbar";
import Tickets from "./pages/Tickets";
import Ticket from "./pages/Ticket";
import MesTickets from "./pages/MesTickets";
import MesTicketsAssignes from "./pages/MesTicketsAssignes";
import UsersList from "./pages/UsersList";

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loadingAuth, setLoadingAuth] = useState(true); // New loading state for auth check
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('user_id');
    const [isNavbarToggled, setIsNavbarToggled] = useState(localStorage.getItem('navbar_state') === 'true' || true);
    const [infoBarVisible, setInfoBarVisible] = useState(true);
    const [contentWidthOffset, setContentWidthOffset] = useState('380px');

    useEffect(() => {
        function detectOS() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/Win/i.test(userAgent)) {
                return "Windows";
            }
            if (/Mac/i.test(userAgent)) {
                return "Mac";
            }
            return "Other";
        }

        const os = detectOS();
        const widthOffset = os === "Windows" ? "400px" : "380px";
        setContentWidthOffset(widthOffset);

    }, []);


    const closeInfobar = () => {
        setInfoBarVisible(false);
    };

    const toggleNavbar = () => {
        setIsNavbarToggled(prevState => {
            const newState = !prevState;
            localStorage.setItem('navbar_state', newState);
            return newState;
        });
    };

    useEffect(() => {
        //toggleNavbar()
    }, [isNavbarToggled]);

    useEffect(() => {
        const checkAuth = async () => {
            if (token && userId) {
                try {
                    const response = await axios.post(`${API_BASE_URL}/check_auth`, {token, userId});
                    if (response.data && response.data.status === 'ok') {
                        setIsAuthenticated(true);
                    } else {
                        setIsAuthenticated(false);
                        localStorage.removeItem('token');
                        localStorage.removeItem('user_id');
                    }
                } catch (error) {
                    console.error('Error checking authentication:', error);
                    setIsAuthenticated(false);
                }
            } else {
                setIsAuthenticated(false);
            }
            setLoadingAuth(false); // Set loading to false after auth check
        };

        checkAuth();
    }, [token, userId]);

    // While the auth check is loading, return null or a placeholder
    if (loadingAuth) {
        return null; // or <div>Loading...</div> for a loading indicator
    }

    return (<AuthProvider>
        <div className="App">
            {infoBarVisible && (<InfoBar
                closeInfoBar={closeInfobar}
                type={"success"}
                data={""}
            />)}
            <div className={"display-flex"}>
                <span onClick={toggleNavbar}>
                    <i className="fa-solid fa-bars toggle-button-navbar"></i>
                </span>
                <div className={isNavbarToggled ? 'navbar-expanded' : 'navbar-collapsed'}>
                    <Navbar setIsNavbarToggled={setIsNavbarToggled}/>

                </div>
                {(isAuthenticated) && <MainSearchBar navBarState={isNavbarToggled}/>}
                <div
                    className={`main-content ${isNavbarToggled ? 'content-collapsed' : 'content-expanded'}`}
                    style={{
                        left: isNavbarToggled ? contentWidthOffset : '0',
                        width: isNavbarToggled
                            ? `calc(100vw - ${contentWidthOffset})`
                            : '100%',
                    }}
                >                    <MainContent>
                    {isAuthenticated ? (<Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/positions/:order_id" element={<PositionDetail/>}/>
                        <Route path="/seo" element={<Seo/>}/>
                        <Route path="/seo-checker" element={<SeoChecker/>}/>
                        <Route path="/qrcodes" element={<QRCodes/>}/>
                        <Route path="/emails" element={<Emails/>}/>
                        <Route path="/dns-lookup" element={<DnsLookup/>}/>
                        <Route path="/dns-propagation" element={<DnsPropagation/>}/>
                        <Route path="/patch-xgrid" element={<PatchXgrid/>}/>
                        <Route path="/sites-publies" element={<SitesPublies/>}/>
                        <Route path="/sites-visu" element={<SitesVisu/>}/>
                        <Route path="/liste-taches" element={<ListeTaches/>}/>
                        <Route path="/tickets" element={<Tickets/>}/>
                        <Route path="/mes-tickets" element={<MesTickets/>}/>
                        <Route path="/mes-tickets-assignes" element={<MesTicketsAssignes/>}/>
                        <Route path="/ticket/:id" element={<Ticket/>}/>
                        <Route path="/calendrier" element={<Calendrier/>}/>
                        <Route path="/trello" element={<Trello/>}/>
                        <Route path="/clients" element={<Clients/>}/>
                        <Route path="/commandes-en-cours" element={<Commandes/>}/>
                        <Route path="/positions-en-cours" element={<PositionsEnCours/>}/>
                        <Route path="/positions-en-suivi" element={<PositionsSuivi/>}/>
                        <Route path="/positions-terminees" element={<PositionsFinished/>}/>
                        <Route path="/maj-bexio" element={<BexioUpdate/>}/>
                        <Route path="/produits" element={<Produits/>}/>
                        <Route path="/creation-taches" element={<CreationTaches/>}/>
                        <Route path="/logout" element={<Logout/>}/>
                        <Route path="/client/:id" element={<Client/>}/>
                        <Route path="/account" element={<Account/>}/>
                        {localStorage.user_role === "Administrateur" && (<>
                            <Route path="/reporting" element={<Reporting/>}/>
                            <Route path="/utilisateurs" element={<UsersList/>}/>
                            <Route path="/renouvellements" element={<Renouvellements/>}/>
                            <Route path="/productivite" element={<Productivity/>}/>
                            <Route path="/liste-toutes-taches" element={<ListeToutesTaches/>}/>
                            <Route path="/positions-supprimees" element={<PositionsDeleted/>}/>
                            <Route path="/register" element={<Register/>}/>
                        </>)}
                    </Routes>) : (<Login/> // Render the Login component when not authenticated
                    )}
                </MainContent>
                </div>

            </div>
        </div>
    </AuthProvider>);
}

export default App;