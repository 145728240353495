import React from 'react';
import {NavLink} from "react-router-dom";

const ClientCard = ({client, displayButtonFiche, headClasses, contentClasses}) => {

    console.log(client)

    return (

        <div className={"mb-20 h-100 min-w-400"} >
            <div >
                <div>
                    <div className={`border-221-1px display-flex gap-20 color-third p-20 bg-eee ${headClasses}`}>
                        <i className="fa-solid fa-circle-info" style={{fontSize: "1.4rem"}}/>
                        <h3 className={"color-third m-0 top-0"}>
                            Informations du client
                        </h3>
                    </div>

                    <div className={`${contentClasses}`}>
                        <div className={"separator mb-20"}></div>

                        {
                            displayButtonFiche && (
                                <div>
                                    <span
                                    className={"display-flex align-items-center mb-10 ml-20"}>
                            <NavLink to={`/client/${client.contact_id}`}
                                     target={"_blank"}
                                     className={'text-decoration-none'}>
                                <span
                                    className={'client-button display-flex justify-content-center align-items-center mr-10 cursor-pointer'}>
                                    <i className="fa-solid fa-external-link mr-10"/>
                                    <span>Accéder à la fiche client</span>
                                 </span>
                            </NavLink>
                        </span>

                                </div>
                            )
                        }

                        <div className={`display-flex justify-content-space-between p-20`}>
                            <div>

                            <span className={'icon-info-client'}>
                                <i className="fa-sharp fa-solid fa-location-dot color-third"></i>
                            </span>
                                <span>{client.name_1}</span>
                                <p style={{marginLeft: "40px"}}>{client.name_2}</p>
                                <p style={{marginLeft: "40px"}}>{client.address}</p>
                                <p style={{marginLeft: "40px"}}>{client.postcode} {client.city}</p>

                                {
                                    client.phone_fixed && (
                                        <p>
                                            <a style={{textDecoration: "none"}} href={"tel:" + client.phone_fixed}>
                                    <span className={'icon-info-client'}>
                                        <i className="fa-solid fa-phone mr-10 color-third"/>
                                    </span>
                                                {client.phone_fixed}
                                            </a>
                                        </p>
                                    )
                                }

                                {
                                    client.mail && (
                                        <p>
                                            <a style={{textDecoration: "none"}} href={"mailto:" + client.mail}>
                                    <span className={'icon-info-client'}>
                                        <i className="fa-solid fa-envelope mr-10 color-third"/>
                                    </span>
                                                <span>{client.mail}</span>
                                            </a>
                                        </p>
                                    )
                                }

                                {
                                    displayButtonFiche && (
                                        <p>
                                            <a style={{textDecoration: "none"}} target={"_blank"}
                                               href={"https://office.bexio.com/index.php/kb_order/show/id/" + client.order_id}
                                               rel="noreferrer">

                                    <span className={'icon-info-client'}>
                                        <i className="fa-solid fa-link color-third" style={{marginRight: "10px"}}/>
                                    </span>
                                                <span>
                                        Contrat Bexio
                                    </span>
                                            </a>
                                        </p>
                                    )
                                }


                                {
                                    client.branch_name && (
                                        <p>
                                <span className={'icon-info-client'}>
                                    <i className="fa-solid fa-industry color-third"/>
                                </span>
                                            <span>{client.branch_name}</span>
                                        </p>
                                    )
                                }

                                {client.url && <p>
                                    <a style={{textDecoration: "none"}} target={"_blank"}
                                       href={client.url}
                                       rel="noreferrer">

                                <span className={'icon-info-client'}>
                                    <i className="fa-solid fa-link color-third" style={{marginRight: "10px"}}/>
                                </span>
                                        <span>
                                    {client.url}
                                </span>
                                    </a>
                                </p>

                                }

                                {
                                    client.contact_firstname && (
                                        <p>
                            <span style={{textDecoration: "none"}}>
                                <span className={'icon-info-client'}>
                                <i className="fa-solid fa-user color-third"
                                   style={{
                                       marginRight: "10px"
                                   }}/>
                                </span>
                                <span>{client.contact_firstname} {client.contact_lastname}</span>
                            </span>
                                        </p>
                                    )
                                }

                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>);
};

export default ClientCard;