import React, {useEffect, useState, Fragment} from 'react';
import {Link, NavLink, useParams} from 'react-router-dom';
import axios from 'axios';
import {API_BASE_URL} from '../config';
import '../assets/css/Client.css';
import Table from "../components/Table";
import SearchBar from "../components/SearchBar";
import ClientInfoForm from "../components/Client/ClientInfoForm";
import ClientCard from "../components/ClientCard";

const Client = () => {
    const {id} = useParams(); // Get the 'id' parameter from the URL
    const [positions, setPositions] = useState([]);
    const [totalPositions, setTotalPositions] = useState([]);
    const [customerName1, setCustomerName1] = useState(null);
    const [customerName2, setCustomerName2] = useState(null);
    const [email, setEmail] = useState(null);
    const [consultantEmail, setConsultantEmail] = useState(null);
    const [feedback, setFeeback] = useState(null);
    const [data, setData] = useState([]);
    const [messageState, setMessageState] = useState('Envoyer');
    const [avis, setAvis] = useState([]); // Initialize as an empty array
    const [isChecked, setIsChecked] = useState(false);
    const [sortBy, setSortBy] = useState({column: null, ascending: true});
    const [formData, setFormData] = useState({
        subject: '', url: '', clientEmail: '', clientName: '', contentFile: '', clientID: ''
    });


    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [siteUrl, setSiteUrl] = useState('');

    const handleTemplateChange = (e) => {
        const value = e.target.value;
        setSelectedTemplate(value);
        setFormData({...formData, subject: value});
        console.log(value)
        if (value !== "Votre nouveau site est en ligne") {
            setSiteUrl('');
        }
    };


    const handleSiteUrlChange = (e) => {
        const value = e.target.value;
        setSiteUrl(value);
        setFormData({...formData, url: value});
    };

    const columnMapping = {
        "Identifiant": "id", "Nom du projet": "title", 'Date de création du projet': "creation_date"
        // Add more mappings as needed
    };
    const handleSort = (columnName) => {
        const actualColumn = columnMapping[columnName] || columnName;
        const ascending = actualColumn === sortBy.column ? !sortBy.ascending : true;
        setSortBy({column: actualColumn, ascending});
    };

    const publishedArray = Array.isArray(positions) ? positions : [];
    const sortedArray = [...publishedArray].sort((a, b) => {
        if (sortBy.column === 'id') {
            return sortBy.ascending ? a.id - b.id : b.id - a.id;
        } else if (sortBy.column === 'title') {
            return sortBy.ascending ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
        } else if (sortBy.column === 'creation_date') {
            const branchNameA = a.branch_names;
            const branchNameB = b.branch_names;

            if (branchNameA === null || branchNameA === undefined) return sortBy.ascending ? 1 : -1;
            if (branchNameB === null || branchNameB === undefined) return sortBy.ascending ? -1 : 1;

            return sortBy.ascending ? branchNameA.localeCompare(branchNameB) : branchNameB.localeCompare(branchNameA);
        }
        return 0; // Default case
    });
    const handleToggle = () => {
        const newValue = !isChecked ? 1 : 0;
        setIsChecked(!isChecked);
        axios.post(`${API_BASE_URL}/update_customer_avis`, {id, avis: newValue});
    };

    useEffect(() => {
        console.log(id);

        // Fetch customer avis
        axios.post(`${API_BASE_URL}/get_customer_avis`, {id: id})
            .then(res => {
                setAvis(res.data.datas);
                // Check if res.data.datas exists and has email property
                if (res.data.datas && res.data.datas.email !== undefined) {
                    setEmail(res.data.datas.email);
                }

            });

        // Fetch customer info
        axios.post(`${API_BASE_URL}/get_customer_info`, {id})
            .then(res => {
                if (res.data[0]) {
                    setData(res.data[0])
                    setConsultantEmail(res.data[0].email)
                    setEmail(res.data[0].mail)
                    setSiteUrl(res.data[0].url)
                    console.log(res.data)
                    setFormData(res.data[0])
                    setCustomerName1(res.data[0].name_1);
                    setCustomerName2(res.data[0].name_2);
                    setIsChecked(res.data[0].avis === 1);
                    document.title = `X | Client - ${res.data[0].name_1}`;
                }
            });

        // Fetch customer positions
        axios.post(`${API_BASE_URL}/get_customer_positions`, {id})
            .then(res => {
                setPositions(res.data);
                setTotalPositions(res.data);
            });
    }, [id]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${day}/${month}/${year} à ${hours}:${minutes}:${seconds}`;
    };

    const handleChangeEmail = (val) => {
        setEmail(val)
    }

    const validateForm = () => {
        if (formData.subject === '') {
            alert("Vous devez sélectionner un modèle d'email");
            return false;
        }

        if (formData.subject === 'Votre nouveau site internet est en ligne' && formData.url === "") {
            alert("Vous devez remplir le champ URL pour cette demande");
            return false;
        }

        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Voulez-vous vraiment envoyer la demande d'avis à ${email} (en copie à ${consultantEmail})`)) {

            const data = {
                consultant: null,
                clientID: id,
                email: email,
                consultantEmail: consultantEmail,
                subject: formData.subject,
                contentFile: formData.contentFile,
                clientName: formData.clientName,
                url: siteUrl
            };

            axios.post(`${API_BASE_URL}/send_mail_avis`, data)
                .then(response => {
                    console.log(response);
                    if (response.data === 'ok') {
                        setMessageState('<i class="fa-solid fa-check"></i>');

                        // Revert to the initial state after 3 seconds
                        setTimeout(() => {
                            setMessageState("Envoyer la demande d'avis");
                        }, 3000);
                    }
                    setFeeback(response.data);
                })
                .catch(error => {
                    alert('Erreur lors de l\'envoi : ', error);
                });
        }
    };


    return (<div>
        <div className={""}
             style={totalPositions.length === 0 ? {backgroundColor: "#ACDCE6", height: "calc(100vh - 80px)"} : {}}>
            <div className={'display-flex flex-direction-column'}>

                <div className={"display-flex"}>
                    <NavLink to={'/clients'} className={"mb-20 back-button"}>
                      <span className={"cursor-pointer display-flex"}>
                        <i className="fa-solid fa-left mr-10 "/>Retour
                      </span>
                    </NavLink>
                </div>


                {totalPositions.length === 0 ? (<div className="client-no-client">
                    <img alt="Toucan tristounet" src="../assets/sad_toucan.jpg"
                         style={{marginBottom: "40px", maxWidth: "100%"}}/>
                    <span>On dirait que ce client n'a pas encore de projet chez Solution Digitale...</span>
                </div>) : (<Fragment>


                    <div className="search-bar mb-0 no-radius-bottom p-0 bg-fourth box-shadow-none"><h2 className={"color-third"}>{data.name_1}</h2></div>

                    <div className={"display-flex mb-40 no-radius-top client-infos-container"}>
                        {/* Fiche client */}

                        <div className={"flex client-compo-left"}>
                            <ClientCard client={data} displayButtonFiche={false} headClasses={"client-infos-bg border-none"}/>
                        </div>

                        {/* Gestion avis */}
                        <div className={"flex p-20 client-compo-right radius-bottom-right-10"}>
                            <div className={"text-left mb-50 flex-direction-column display-flex "}>
                                <h3 className={"color-third"}>Gestion des avis</h3>
                                <div>
                                    <span>Statut de l'avis client sur Google</span>
                                </div>
                                <label className="switch mt-20">
                                    <input type="checkbox" checked={isChecked} onChange={handleToggle}/>
                                    <span className="slider"></span>
                                </label>
                                <div className={"mt-10"}>

                                <span className={"mt-10"} style={{fontStyle: "italic", fontSize: ".8rem"}}>
                                    Si la case est cochée, le client a déjà laissé son avis sur Google.
                                </span>
                                </div>
                            </div>

                            <div className={"text-left mb-50"}>
                                <h3 className={"color-third"}>Gestionnaire d'envoi des avis (fiche Google)</h3>
                                <form onSubmit={handleSubmit}>
                                    <label><i>Pour mettre plusieurs emails, les séparer par une virgule.</i></label>
                                    <div className={"display-flex gap-10 mt-20 btn-avis-container-global"}>
                                        <div className={"btn-avis-container mb-20"}>
                                            <div className={"display-grid text-left"}>
                                                <input
                                                    className={"text-center input-base p-15 "}
                                                    name="clientEmail"
                                                    value={email}
                                                    onChange={e => handleChangeEmail(e.target.value)}
                                                    placeholder={"Email client (aucun trouvé)"}
                                                    style={{minWidth: 300}}
                                                />
                                            </div>
                                            <div className={""}>
                                                <select
                                                    id="subject"
                                                    name="subject"
                                                    className={"text-center input-base p-15 "}
                                                    value={formData.subject}
                                                    onChange={handleTemplateChange}
                                                    style={{minWidth: 300}}
                                                >
                                                    <option value="">Choisir un template d'email</option>
                                                    <option value="Demande d'avis">Demande d'avis</option>
                                                    <option value="Rappel demande d'avis">Rappel demande d'avis</option>
                                                    <option value="Votre nouveau site est en ligne">Votre nouveau site
                                                        internet
                                                        est en
                                                        ligne
                                                    </option>
                                                    <option value="Vos supports de communication sont prêts">Vos
                                                        supports de
                                                        communication sont prêts
                                                    </option>
                                                    <option value="Votre présence sur les réseaux sociaux">Votre
                                                        présence
                                                        sur
                                                        les
                                                        réseaux sociaux
                                                    </option>
                                                    <option value="Lancement de votre campagne Google Ads">Lancement de
                                                        votre
                                                        campagne
                                                        Google Ads
                                                    </option>
                                                </select>
                                            </div>
                                            {(selectedTemplate === "Votre nouveau site est en ligne") && (
                                                <div className={"display-grid text-left"}>
                                                    <input
                                                        className={"text-center input-base p-15 "}
                                                        name="siteUrl"
                                                        value={siteUrl}
                                                        required={true}
                                                        onChange={handleSiteUrlChange}
                                                        placeholder={"URL du site"}
                                                        style={{minWidth: 300}}
                                                    />
                                                </div>)}
                                        </div>

                                    </div>
                                    <div className={"btn-avis-container w-100"}>
                                        <button type={"submit"}
                                                className={"btn-submit-account strong m-0 p-15 display-flex justify-content-center mt-20 min-w-200"}
                                                dangerouslySetInnerHTML={{__html: messageState}}></button>
                                    </div>
                                </form>
                            </div>

                            <div className={"text-left mb-50"}>
                                <h3 className={"color-third"}>Demandes d'avis envoyées</h3>
                                {avis ? avis.map((el, index) => (<div key={index}>
                                    <span><strong>{el.type}</strong> envoyé le {formatDate(el.created_date)} à {el.email}</span>
                                </div>)) : <span>Aucune demande d'avis n'a pour le moment été envoyée.</span>}
                            </div>
                        </div>


                    </div>

                    <span>
                    <h2 className={"color-third"}>Liste des projets</h2></span>

                    <SearchBar
                        totalPositions={totalPositions}
                        setPublished={setPositions}
                        autoFocus={false}
                        totalPositionsLength={publishedArray}
                        searchLabel={'projets trouvés pour ce client'}
                    />
                    <Table
                        typeOfPosition={"finished"}
                        data={sortedArray}
                        sortBy={sortBy}
                        hidePagination={false}
                        maxEntries={50}
                        headersData={['id_customer', 'title_customer', 'order_start_date']}
                        headers={["Identifiant", "Nom du projet", 'Date de création du projet']}
                        handleSort={handleSort}
                    />

                </Fragment>)}
            </div>
        </div>
    </div>);
};

export default Client;