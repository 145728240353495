import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Link} from "react-router-dom";
import {API_BASE_URL} from "../config";

const ClosedPositions = ({id}) => {

    const [openPositions, setOpenPositions] = useState([]);

    const user_id = id.contact_id;
    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_finished_positions_specific_customer/`, {user_id})
            .then(res => {
                setOpenPositions(res.data)
                console.log(res.data)
            })
    }, [id, user_id]);

    return (
        <div className={"p-15 flex bg-white border-radius-10 border-solid-1px no-radius-top no-border-top"}
             style={{borderColor: "#ddd"}}>

            <div className={"positions-list w-100"}>
                {
                    openPositions.map((el, index) => (

                            <Link to={'/positions/' + el.id} className={""} key={index}
                                  style={{textDecoration: "none", color: "var(--third)", maxWidth: 347}}>
                                <div className={"positions-list-item p-10 mb-5 border-radius-5 w-100"}>
                                    <i className="fa-solid fa-link" style={{marginRight: "10px"}}></i>
                                    <span>{el.id} </span>

                                    <span className={"w-100 crop-text"}
                                          dangerouslySetInnerHTML={{__html: el.title}}/>

                                </div>
                            </Link>

                        )
                    )
                }
            </div>
        </div>
    );
};

export default ClosedPositions;