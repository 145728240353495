import React, {Fragment, useEffect, useState} from 'react';
import Slider from "rc-slider";
import axios from "axios";
import {API_BASE_URL} from "../config";
import {NavLink} from "react-router-dom";
import 'rc-slider/assets/index.css';
import SearchBar2 from "./SearchBar2";
import TimeInput from "./TimeInput";
import {datetime} from "react-table/src/sortTypes";

const Tasks = ({
                   searchBar, searchLabel,
                   title,
                   displayAdminBar,
                   displayCheckbox,
                   tasks: initialTasks,
                   headers,
                   headersData,
                   fetchTasks
               }) => {
    // Local state to manage tasks internally
    const [durationLighting, setDurationLighting] = useState(false);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [users, setUsers] = useState([]);
    const [datasFound, setDatasFound] = useState(initialTasks);
    const [isVisible, setIsVisible] = useState(true);
    const [currentDay, setCurrentDay] = useState(null);
    const [tasks, setTasks] = useState(initialTasks || []);

    const handleAction = (action, id, position_id) => {

        console.log(position_id)
        console.log(id)
        if (action === 'delete_task') {
            const res = window.confirm("Voulez-vous vraiment supprimer cette tâche ?");
            if (res) {
                axios.post(`${API_BASE_URL}/delete_task`, {id, position_id})
                    .then(response => {
                        if (response.data.status === 'ok') {
                            setTasks(prevTasks => {
                                const updatedTasks = prevEntries => prevEntries.filter(entry => entry.id !== id)
                                setDatasFound(updatedTasks); // Synchronize with datasFound
                                return updatedTasks;
                            });

                        } else {
                            console.error('error');
                        }
                    })
                    .catch(error => {
                        console.error('Error deleting task:', error);
                    });
            }
        }
    };

    useEffect(() => {

        // Au début, on initialise les tâches trouvées avec les tâches passées en param au composant
        setDatasFound(initialTasks)
        setTasks(initialTasks);
        // Set the current day of the week (0 = Sunday, 6 = Saturday)
        const today = new Date().getDay();
        setCurrentDay(today);
        axios.post(`${API_BASE_URL}/get_users`)
            .then(res => {
                const {data} = res;
                if (data && data.length > 0) {
                    const userNamesArray = data.map(user => user);
                    setUsers(userNamesArray);

                } else {
                    console.error('No user data received');
                }
            })
            .catch(error => {
                console.error('Error fetching user data:', error);
            });
    }, [initialTasks]);

    const handleFinishDate = (tasksId, newDate) => {
        const updatedTaskIds = Array.isArray(tasksId) ? tasksId : [tasksId];
        updatedTaskIds.forEach(taskId => {

            axios.post(`${API_BASE_URL}/update_task_finish_date`, {time: newDate, id_task: taskId})
                .then(e => {
                    setTasks(prevTasks => {
                        const updatedTasks = prevTasks.map(task =>
                            task.id === taskId ? { ...task, finished_at: newDate } : task
                        );
                        setDatasFound(updatedTasks); // Synchronize with datasFound
                        return updatedTasks;
                    });
                })
                .catch(error => {
                    console.error("Error updating task finish date:", error);
                });

        })
    };
    const handleFinishDateCustom = (tasksId, newDate) => {
        console.log(tasksId)
        console.log(newDate)
        const updatedTaskIds = Array.isArray(tasksId) ? tasksId : [tasksId]; // Ensure tasksId is an array
        updatedTaskIds.forEach(taskId => {
            axios.post(`${API_BASE_URL}/update_task_finish_date_custom`, { time: newDate, id_task: taskId })
                .then((res) => {
                    console.log(tasks)
                    setTasks(prevTasks => {
                        const updatedTasks = prevTasks.map(task =>
                            task.id === taskId ? { ...task, start_date: newDate } : task
                        );
                        setDatasFound(updatedTasks); // Synchronize with datasFound
                        return updatedTasks;
                    });
                })
                .catch(error => {
                    console.error("Error updating task finish date custom:", error);
                });
        });
    };

    const handleUserChange = (tasksId, userId) => {
        const updatedTaskIds = Array.isArray(tasksId) ? tasksId : [tasksId]; // Ensure tasksId is an array

        updatedTaskIds.forEach(taskId => {

            setTasks(prevTasks => {
                const updatedTasks = prevTasks.map(task =>
                    task.id === taskId ? { ...task, user_id: userId } : task
                );
                setDatasFound(updatedTasks); // Synchronize with datasFound
                return updatedTasks;
            });

            axios.post(`${API_BASE_URL}/update_user_task`, {
                user_id: userId, task_id: taskId, admin_id: localStorage.user_id
            })
                .then((res) => {
                    if (res.status !== 200) { // Revert the optimistic update if the API call fails
                        setTasks(prevTasks => {
                            const updatedTasks = prevTasks.map(task =>
                                task.id === taskId ? { ...task, user_id: null } : task
                            );
                            setDatasFound(updatedTasks); // Synchronize with datasFound
                            return updatedTasks;
                        });
                    }
                })
                .catch(error => {
                    console.error('Error updating user task:', error);
                    // Revert the optimistic update if the API call fails
                    setTasks(prevTasks => prevTasks.map(task => task.id === taskId ? {
                        ...task, user_id: null // Assuming `null` reverts the assignment
                    } : task));
                });
        });
    };

    const handleSelectAll = (event) => {
        const userName = localStorage.getItem('user_name'); // Retrieve the username from localStorage
        const userRole = localStorage.getItem('user_role'); // Retrieve the user role from localStorage

        if (event.target.checked) {
            const allTaskIds = tasks
                .filter(task => userRole === 'Administrateur' || task.username === userName) // Check role first
                .map(task => task.id); // Map only the task IDs
            setSelectedTasks(allTaskIds);
        } else {
            setSelectedTasks([]);
        }
    }

    const handleCheckboxChange = (taskId) => {
        if (selectedTasks.includes(taskId)) {
            setSelectedTasks(selectedTasks.filter(id => id !== taskId));
        } else {
            setSelectedTasks([...selectedTasks, taskId]);
        }
    };

    const formatDateForInput = (date, formatForDisplay = false, isDateTime = false) => {
        if (!date) {
            return formatForDisplay ? 'jj/mm/aaaa hh:mm:ss' : '';  // Placeholder based on context
        }

        const d = new Date(date);
        if (isNaN(d.getTime())) {
            return formatForDisplay ? 'jj/mm/aaaa hh:mm:ss' : '';  // Placeholder for invalid date
        }

        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        const hours = d.getHours().toString().padStart(2, '0');
        const minutes = d.getMinutes().toString().padStart(2, '0');
        const seconds = d.getSeconds().toString().padStart(2, '0');

        if (formatForDisplay) {
            // For display format (jj/mm/yyyy hh:mm:ss)
            return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
        } else if (isDateTime) {
            // For datetime-local input (yyyy-MM-ddTHH:mm:ss)
            return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
        } else {
            return `${year}-${month}-${day}`;  // For date input (yyyy-MM-dd)
        }
    };
    const handleTimeSpent = (user, time, id) => {

        const updatedTaskIds = Array.isArray(id) ? id : [id];
        updatedTaskIds.forEach(taskId => {

            axios.post(`${API_BASE_URL}/update_time_spent`, { user, time, id_task: id })
                .then(() => {
                    setTasks(prevTasks => {
                        const updatedTasks = prevTasks.map(task =>
                            updatedTaskIds.includes(task.id) ? { ...task, time_spent: time } : task
                        );
                        setDatasFound(updatedTasks); // Synchronize with datasFound
                        return updatedTasks;
                    });
                    fetchTasks(); // Refresh tasks from the server
                })
                .catch(error => {
                    console.error("Error updating task finish date:", error);
                });

        })
    }

    useEffect(() => {
        if (durationLighting) {
            alert(durationLighting);
            setDurationLighting(false); // Reset the state after showing the alert
        }
    }, [durationLighting]);


    const handleProgressChange = (taskId, value) => {
        // Check if today is Saturday (6) or Sunday (0)
        if (currentDay === 0 || currentDay === 6) {
            alert('On ne remplit pas ses tâches le week-end ;)');
            return; // Prevent further execution
        }

        setTasks(prevTasks => {
            const updatedTasks = prevTasks.map(task =>
                task.id === taskId ? { ...task, progress: value } : task
            );
            setDatasFound(updatedTasks); // Synchronize with datasFound
            return updatedTasks;
        });

        // Proceed with the API call if the day is valid
        axios.post(`${API_BASE_URL}/update_task_progress`, {
            taskId, progress: value,
        }).then(response => {
            const {data} = response;
            if (data.status !== 'ok') {
                console.error('Error updating task progress:', data.message);
                setDurationLighting(data.message);
            }
        }).catch(error => {
            console.error('Error updating task progress:', error);
            setDurationLighting(true);
        });
    };

    return <div>
        {/* Barre de titre */}
        {title && (
            <div className="toggle-button mb-20" onClick={() => setIsVisible(!isVisible)}>
                {title}
            </div>
        )}

        {isVisible && (
            <>
                {/* Barre de recherche */}
                {searchBar && (
                    <SearchBar2
                        totalPositionsLength={datasFound}
                        datasFound={setDatasFound}
                        totalDatas={initialTasks}
                        datas={initialTasks}
                        searchLabel={searchLabel}
                    />
                )}
            </>
        )}
        <div style={{marginBottom: "-10px"}}>
            <div className="toggle-container">

                {displayAdminBar && <div className="selected-tasks-options">
                    {(localStorage.user_role !== 'test') && (<Fragment>
                                <span className={'button-action shadow mt-10 mr-10'}
                                      style={{backgroundColor: '#F6F8FA'}}>
                                    Assigner à...
                                </span>
                        <select className={'select-assign'} onChange={(e) => {
                            handleUserChange(selectedTasks, e.target.value);
                        }}
                        >
                            {users.map((user) => (<option
                                value={user.id}
                                name={user.username}
                            >
                                {user.username}
                            </option>))}
                        </select>
                    </Fragment>)}

                </div>}
                {
                    isVisible &&
                    <div className="show">
                        <table className="task-table">
                            <thead>
                            <tr>
                                {displayCheckbox && <th><input type="checkbox" onChange={handleSelectAll}/></th>}
                                {headers.map((header, index) => <th key={index} id={header}>{header}</th>)}

                            </tr>
                            </thead>
                            <tbody>
                            {
                                datasFound !== undefined && (

                                    datasFound.map((item, rowIndex) => <tr key={rowIndex}>
                                        {displayCheckbox && (<td
                                            style={{textAlign: "center", cursor: "pointer"}}
                                            onClick={(e) => {
                                                if (e.target.tagName !== 'INPUT') {
                                                    handleCheckboxChange(item.id);
                                                }
                                            }}
                                        >
                                            {(localStorage.user_name === item.username || localStorage.user_role === 'Administrateur') &&

                                                <input
                                                    type="checkbox"
                                                    checked={selectedTasks.includes(item.id)}
                                                    onClick={(e) => e.stopPropagation()}
                                                    onChange={() => handleCheckboxChange(item.id)}
                                                />

                                            }
                                        </td>)}

                                        {headersData.map((header, colIndex) => {

                                            if (header === 'name_1') {
                                                return (<td key={colIndex}>
                                                    <a
                                                        href={`/client/${item.contact_id}`}
                                                        className="text-decoration-none text-left"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {item.name_1}
                                                        <p>{item.name_2}</p>
                                                    </a>
                                                </td>);
                                            }
                                            if (header === 'customer') {
                                                return (<td key={colIndex}>
                                                    <a
                                                        href={`/client/${item.contact_id}`}
                                                        className="text-decoration-none text-left"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <p>{item.name_1}</p>
                                                        <p>{item.name_2}</p>
                                                    </a>
                                                </td>);
                                            }
                                            if (header === 'estimated_duration') {

                                                const totalMinutes = parseInt(item.estimated_duration, 10); // Ensure it's treated as a number

                                                // Calculate hours and remaining minutes
                                                const hours = Math.floor(totalMinutes / 60); // Divide total minutes by 60 to get hours
                                                const minutes = totalMinutes % 60; // Get the remainder as minutes

                                                // Format as HH:MM
                                                const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

                                                return (
                                                    <td key={colIndex}>
                                                        <p className={'text-center'}>{formattedTime}</p>
                                                    </td>
                                                );
                                            }



                                            if (header === 'time_spent_reporting') {
                                                const timeParts = item.time_spent.split(':'); // Split time string by ':'
                                                const hours = parseInt(timeParts[0], 10); // Get hours
                                                const minutes = parseInt(timeParts[1], 10); // Get minutes

                                                // Build the time string based on hours and minutes
                                                let displayTime = '';
                                                if (hours > 0) {
                                                    displayTime += `${hours} heure${hours > 1 ? 's' : ''}`; // Pluralize 'heure' if necessary
                                                }
                                                if (minutes > 0) {
                                                    if (hours > 0) displayTime += ' '; // Add space between hours and minutes
                                                    displayTime += `${minutes} minute${minutes > 1 ? 's' : ''}`; // Pluralize 'minute' if necessary
                                                }

                                                return (
                                                    <td key={colIndex} style={{minWidth: "180px", textAlign: "center"}}>
                                                        {displayTime}
                                                    </td>
                                                );
                                            }


                                            if (header === 'task_price_prorata') {
                                                return (
                                                    <td key={colIndex}>
                                                        {new Intl.NumberFormat('de-CH', {
                                                            style: 'currency',
                                                            currency: 'CHF'
                                                        }).format(item.task_price_prorata)}
                                                    </td>
                                                );
                                            }


                                            if (header === 'username_reporting') {
                                                return (<td key={colIndex}>

                                                    {item.username}
                                                </td>);
                                            }
                                            if (header === 'title_finished' || header === 'title') {
                                                return (<td key={colIndex}>
                                                    <NavLink
                                                        to={`/positions/${item.position_id}`}
                                                        className="text-decoration-none text-left"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <strong>
                                                            {item.title}

                                                        </strong>

                                                    </NavLink>
                                                </td>);
                                            }
                                            if (header === 'title_open_position') {
                                                return (<td key={colIndex}>

                                                    <strong>
                                                        {item.title}

                                                    </strong>

                                                </td>);
                                            }
                                            if (header === 'finished_at') {
                                                return (<td key={colIndex} className={'text-center'}>
                                                    <span>{formatDateForInput(item.finished_at, true)}</span>
                                                </td>);
                                            }
                                            if (header === 'username') {
                                                return (<td>
                                                        {localStorage.user_role ? (
                                                            <select
                                                                className={"input text-center"}
                                                                style={{
                                                                    minWidth: 150,
                                                                    padding: "10px 5px",
                                                                    fontFamily: "Montserrat"
                                                                }}
                                                                onChange={(e) => {
                                                                    handleUserChange(item.id, e.target.value);
                                                                }}
                                                                value={item.user_id || ""} // Ensure correct value is set, empty string for "Non assigné"
                                                            >
                                                                <option value="">Non assigné</option>
                                                                {/* Option for "Non assigné" */}
                                                                {users.map((user) => (
                                                                    <option key={user.id} value={user.id}>
                                                                        {user.username}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            <div>
                                                                {item.username || "Non assigné"} {/* Display "Non assigné" if item.username is null */}
                                                            </div>
                                                        )}
                                                    </td>
                                                );
                                            }if (header === 'username_ended') {
                                                return (<td>
                                                                        {item.username}

                                                    </td>
                                                );
                                            }
                                            if (header === 'progress') {
                                                return (<td key={colIndex} className="progress-td">
                                                    {((parseInt(localStorage.user_id)) === parseInt(item.user_id) || localStorage.user_role === 'Administrateur') && (
                                                        <Slider
                                                            min={0}
                                                            max={100}
                                                            defaultValue={item.progress}
                                                            onChangeComplete={value => handleProgressChange(item.id, value)} // Call after change is done
                                                        />)}
                                                    <span style={{
                                                        display: "flex", justifyContent: "center", marginTop: "10px"
                                                    }}>
                                                            {item.progress}%
                                                        </span>
                                                </td>);
                                            }
                                            if (header === 'intern_name') {
                                                return (<td key={colIndex} className="progress-td">
                                                    <span className={""}>{item.intern_name}</span>
                                                </td>);
                                            }
                                            if (header === 'finish_date_reporting') {
                                                const finishDate = new Date(item.finish_date);  // Parse the finish_date string into a Date object
                                                const formattedDate = `${finishDate.getDate().toString().padStart(2, '0')}/${(finishDate.getMonth() + 1).toString().padStart(2, '0')}/${finishDate.getFullYear()}`;

                                                return (
                                                    <td key={colIndex}>
                                                        <span>
                                                            {formattedDate}
                                                        </span>
                                                    </td>
                                                );
                                            }
                                            if (header === 'finish_date_custom') {
                                                const finishDate = new Date(item.start_date);  // Parse the finish_date string into a Date object
                                                let formattedDate = `${finishDate.getDate().toString().padStart(2, '0')}/${(finishDate.getMonth() + 1).toString().padStart(2, '0')}/${finishDate.getFullYear()}`;

                                                return (<td>
                                                    <div className={'input-time-container'}>
                                                        {
                                                            localStorage.user_role === 'Administrateur' ?

                                                                <input
                                                                    className={'input-time input'}
                                                                    type={"datetime-local"}
                                                                    onChange={(e) => handleFinishDateCustom(item.id, e.target.value)}
                                                                    value={formatDateForInput(item.start_date, false, true)}  // datetime-local format (yyyy-MM-ddTHH:mm:ss)
                                                                /> :
                                                                <span>{(formattedDate !== '01/01/1970') && formattedDate}</span>
                                                        }
                                                    </div>
                                                </td>);
                                            }
                                            if (header === 'finish_date') {
                                                return (<td>
                                                    <div className={'input-time-container'}>
                                                        <input
                                                            className={'input-time input'}
                                                            type={"datetime-local"}
                                                            onChange={(e) => handleFinishDate(item.id, e.target.value)}
                                                            value={formatDateForInput(item.finished_at, false, true)}  // datetime-local format (yyyy-MM-ddTHH:mm:ss)
                                                        />
                                                    </div>
                                                </td>);
                                            }
                                            if (header === 'time_spent') {
                                                return (
                                                    <td>
                                                        <div className="input-time-container">
                                                            {((parseInt(localStorage.user_id)) === parseInt(item.user_id) || localStorage.user_role === 'Administrateur') && (
                                                                <TimeInput item={item} handleTimeSpent={(time) => handleTimeSpent(item.user_id, time, item.id)} />
                                                            )}
                                                        </div>
                                                    </td>
                                                );
                                            }

                                            if (header === 'start_date') {
                                                return (<td>
                                                    <div className={'input-time-container'}>

                                                        <span>{formatDateForInput(item.start_date, true)}</span>

                                                    </div>
                                                </td>);
                                            }
                                            if (header === 'delete_task' && localStorage.user_role === 'Administrateur') {
                                                return (
                                                    <td>
                                            <span className="info-branch">
                                             <i className="orangered-21pts cursor-pointer position-icon fa-solid position-icon fa-trash"
                                                onClick={() => handleAction('delete_task', item.id, item.position_id)}></i>
                                            </span>
                                                    </td>);
                                            }
                                            return (<td key={colIndex}>
                                                <p className="t-0 d-ib">{item[header]}</p>
                                            </td>);
                                        })}
                                    </tr>))}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>
    </div>;
};

export default Tasks;
